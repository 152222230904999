const ja = {
  twoFactorAuth: "2要素認証",
  Integrations: "外部連携",
  environmentSettings: "環境設定",
  PrivacyPolicy: "プライバシーポリシー",
  and: "と",
  TermsService: "利用規約",
  Settings: "設定",
  Disconnect: "解除する",
  Connect: "接続する",
  Cancel: "キャンセル",
  Save: "保存する",
  // エラーメッセージ
  NotTwoFactor: "2段階認証の設定に失敗しました",
  ShowQRCode: "QRコードを表示する",
  PINCode: "PINコード（必須）",
  verificationCode: "確認コードを入力してください",
  verificationAlready: "2段階認証が設定済みです",
  Create: "登録",
  Required: "必須になります",
  digitsCode: "6桁で入力してください",
  halfWidthNumbers: "半角数字で入力してください",
}
export default ja
