import React from "react"

import { Box, Typography } from "@mui/material"

// コンポーネントのプロップス型定義
interface MobileHomeReserveProps {
  selectedDate: Date
}

export const MobileHomeReserve: React.FC<MobileHomeReserveProps> = ({
  selectedDate,
}) => {
  // 日付のフォーマット関数
  const formatDate = (date: Date) => {
    return `${date.getFullYear()}年${date.getMonth() + 1}月${date.getDate()}日`
  }

  return (
    <Box
      sx={{
        padding: 2,
        backgroundColor: "#FFFFFF",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <Typography variant="h6">
        座席予約 - {formatDate(selectedDate)}
      </Typography>
      {/* 将来的に詳細な予約コンポーネントに置き換え予定 */}
      <Box sx={{ mt: 2 }}>
        <Typography>
          選択された日付: {selectedDate.toLocaleDateString()}
        </Typography>
        {/* 追加の予約関連コンテンツをここに追加 */}
      </Box>
    </Box>
  )
}
