import React, { Suspense } from "react"
import { Link } from "react-router-dom"

import { CircularProgress, styled } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { EmployeeHomeContent } from "./EmployeeHomeContent"

////////// CustomEmployeeHomeContent

const CustomEmployeeHomeContent = styled(EmployeeHomeContent)(() => ({
  width: "450px",
}))

////////// Title

const EmployeeHomeScheduleTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  margin: "0 0 8px 0",
})

const EmployeeHomeScheduleTitle: React.FC = () => {
  // 言語切り替え
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <>
      <EmployeeHomeScheduleTitleStyle>
        {translations.status}
      </EmployeeHomeScheduleTitleStyle>
    </>
  )
}

////////// Content

const EmployeeHomeScheduleContent = React.lazy(() =>
  import("./schedule/EmployeeHomeScheduleMain").then(
    ({ EmployeeHomeScheduleMain }) => ({
      default: EmployeeHomeScheduleMain,
    })
  )
)

////////// Icon

const EmployeeLeftIconStyled = styled("div")({
  display: "flex",
  alignItems: "center",
})

const EmployeeLeftIcon = (
  <EmployeeLeftIconStyled>
    <img
      src={`${process.env.PUBLIC_URL}/images/icons/active-calendar.svg`}
      style={{
        height: "20px",
        width: "20px",
      }}
    />
  </EmployeeLeftIconStyled>
)

const EmployeeRightIconStyled = styled("div")({
  height: "100%",
})

const EmployeeRightIcon = (
  <EmployeeRightIconStyled>
    <Link
      to={"/employee/calendar/days"}
      style={{
        height: "100%",
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={`${process.env.PUBLIC_URL}/images/icons/next-gray.svg`}
        style={{
          height: "15px",
          width: "15px",
        }}
      />
    </Link>
  </EmployeeRightIconStyled>
)

export const EmployeeHomeSchedule = () => {
  return (
    <>
      <CustomEmployeeHomeContent
        title={<EmployeeHomeScheduleTitle />}
        content={
          <>
            <Suspense fallback={<CircularProgress />}>
              <EmployeeHomeScheduleContent />
            </Suspense>
          </>
        }
        areaName={"Schedule"}
      />
    </>
  )
}
