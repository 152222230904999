import { useState, useCallback } from "react"

import { fetchMyScheduleRequest } from "../../../../api/employee/scheduleRequest"
import { MyScheduleRequestType, MySchedule } from "./type"

export const useScheduleHome = () => {
  const [mySchedules, setMySchedules] = useState<MySchedule>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchMySchedule = useCallback(async (data: MyScheduleRequestType) => {
    try {
      setLoading(true)
      setError(null)
      const response = await fetchMyScheduleRequest({
        start_date: data.start_date,
        end_date: data.end_date,
      })

      if (response.status === 200 && response.data?.my_schedule) {
        setMySchedules(response.data.my_schedule)
      } else {
        setError("スケジュールの取得に失敗しました")
        setMySchedules([]) // 不完全なレスポンスの場合は空配列にする
      }
    } catch (error: any) {
      setError(error.message || "予期せぬエラーが発生しました")
      setMySchedules([])
    } finally {
      setLoading(false)
    }
  }, []) // 依存配列を空にして、関数インスタンスの再生成を防ぐ

  return {
    mySchedules,
    fetchMySchedule,
    loading,
    error,
  }
}
