import * as React from "react"
import { memo, useContext, useEffect } from "react"
import { IoMdCalendar } from "react-icons/io"
import {
  MdHome,
  MdChairAlt,
  MdSettings,
  MdPerson,
  MdGroups,
  MdBusiness,
  MdBarChart,
} from "react-icons/md"
import { useNavigate, useLocation, useParams } from "react-router-dom"

import { ListItemButton, ListItemIcon, styled, Box } from "@mui/material"

import { useLanguage } from "../../../contexts/LanguageContext"
import { MyScheduleRequestType } from "../../../models/employee/useSchedule/useScheduleHome/type"
import { RoleContext } from "../../../providers/RoleProvider"
import enTranslations from "../../../translations/sideBar/en"
import jaTranslations from "../../../translations/sideBar/ja"
import { oneWeek } from "../../../utils/oneWeek"
import { weekStatus } from "../../../utils/weeks"

const CustomListItemButton = styled(ListItemButton)(() => ({
  display: "flex",
  flexDirection: "column",
  width: "66px",
  padding: "0",
  position: "relative",
  "&:hover": {
    "& .custom-box": {
      visibility: "visible",
    },
  },
}))

const SelectListItemIcon = styled(ListItemIcon)(() => ({
  justifyContent: "center",
  padding: "8px 0",
  margin: "7px 0",
  display: "flex",
  borderRadius: "6px",
  opacity: "1",
  backgroundColor: "rgba(255, 255, 255, 0.16)",
  "& img": {
    filter: "brightness(0) invert(1)",
  },
}))
const CustomListItemIcon = styled(ListItemIcon)(() => ({
  justifyContent: "center",
  padding: "8px 0",
  margin: "7px 0",
  display: "flex",
  borderRadius: "6px",
  "& img": {
    filter: "brightness(0) invert(1)",
  },
}))

const CustomBox = styled(Box)(() => ({
  zIndex: 100,
  visibility: "hidden",
  position: "absolute",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  top: "-15px",
  height: "20px",
  fontSize: "10px",
  textAlign: "center",
  padding: "5px",
  color: "#454545",
  backgroundColor: "white",
  fontFamily: "Noto Sans",
  boxShadow: "0px 1px 10px #33333334;",
  borderRadius: "4px",
}))

// アイコンの下に表示する文言のスタイル
const IconText = styled("div")(() => ({
  fontSize: "11px",
  color: "#FFFFFF",
  fontFamily: "Noto Sans",
  textAlign: "center",
  marginTop: "2px",
  width: "100%",
}))

type List = {
  name: string
  icon?: React.ElementType
  url: string[]
}

export const companyListItems = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const { isTeamLeader, isAdmin } = useContext(RoleContext)
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations

  const companyUrlParams = useParams<{
    id: string
    branch_id: string
    floor_id: string
    layout_id: string
  }>()

  // NOTE:ウェルネスサーベイは相談の上保留になったためコメントアウト
  const companyList: List[] = [
    {
      name: translations.home,
      icon: MdHome,
      url: ["/company/home", "/company/dashboard"],
    },
    {
      name: translations.locationManagement,
      icon: MdBusiness,
      url: [
        "/company/branches",
        "/company/branches/new",
        `/company/branches/${companyUrlParams.id}`,
        `/company/branches/${companyUrlParams.id}/edit`,
        `/company/branches/${companyUrlParams.branch_id}/floors/${companyUrlParams.floor_id}/layouts/new`,
        `/company/branches/${companyUrlParams.branch_id}/floors/${companyUrlParams.floor_id}/layouts/${companyUrlParams.layout_id}`,
        `/company/branches/${companyUrlParams.id}/floors/${companyUrlParams.floor_id}/qrcode`,
      ],
    },
    {
      name: translations.employeeManagement,
      icon: MdPerson,
      url: [
        "/company/employees",
        "/company/employees/new",
        `/company/employees/${companyUrlParams.id}`,
        `/company/employees/${companyUrlParams.id}/edit`,
      ],
    },
    {
      name: translations.terms,
      icon: MdGroups,
      url: [
        "/company/teams",
        "/company/teams/new",
        `/company/teams/${companyUrlParams.id}/edit`,
      ],
    },
    // {
    //   name: "ウェルネスサーベイ",
    //   image: "survey",
    //   url: "#",
    // },
    {
      name: translations.reports,
      icon: MdBarChart,
      url: [
        "/company/reports",
        "/company/reports/attendances",
        "/company/reports/operations",
        "/company/reports/meeting_rooms",
        "/company/reports/areas",
      ],
    },
    {
      name: translations.settings,
      icon: MdSettings,
      url: [
        "/company/settings/payment",
        "/company/settings/external-system-link",
        "/company/settings/setup-mfa",
        "/company/settings/environment",
        "/company/settings/environment/work_hour",
        "/company/settings/environment/weekly_off_day",
        "/company/settings/environment/signage",
        "/company/settings/payment/methods",
        "/company/settings/payment/paid",
        "/company/settings/payment/credit_card",
        "/company/settings/payment/plan",
      ],
    },
  ]

  return (
    <>
      {companyList.map((list, id) => {
        // 「従業員管理」または「レポート」の場合、isTeamLeaderがtrueでなければスキップ
        if (
          (list.name === translations.home ||
            list.name === translations.terms ||
            list.name === translations.locationManagement ||
            list.name === translations.settings) &&
          !isAdmin
        ) {
          return null
        }

        const ListIconComponent = list.url.includes(location.pathname)
          ? SelectListItemIcon
          : CustomListItemIcon
        const IconComponent = list.icon

        return (
          <CustomListItemButton
            key={id}
            onClick={() => {
              // レポートの場合、isTeamLeaderのURL制御
              if (list.name === translations.reports && isTeamLeader) {
                navigate("/company/reports/attendances")
              } else {
                navigate(list.url[0])
              }
            }}
          >
            <CustomBox className="custom-box">{list.name}</CustomBox>
            <ListIconComponent>
              {IconComponent && <IconComponent size={24} color="#FFFFFF" />}
            </ListIconComponent>
          </CustomListItemButton>
        )
      })}
    </>
  )
}

interface Props {
  setBeginningOfWeek: React.Dispatch<React.SetStateAction<Date>>
  setEndOfWeek: React.Dispatch<React.SetStateAction<Date>>
  formatDate: (date: Date) => string
  fetchMySchedule: (data: MyScheduleRequestType) => Promise<void>
}

export const EmployeeListItems: React.FC<Props> = memo(
  ({ setBeginningOfWeek, setEndOfWeek, fetchMySchedule }: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const employeeUrlParams = useParams<{
      id: string
    }>()
    // 言語切り替え
    const { language } = useLanguage()
    const translations = language === "en" ? enTranslations : jaTranslations

    const weekObject = weekStatus()
    const { setTargetWeeks } = oneWeek()

    const thisWeekSchedule = () => {
      const { newBeginningOfWeek, newEndOfThisWeek } = setTargetWeeks(
        new Date()
      )
      fetchMySchedule({
        start_date: weekObject.formatDate(newBeginningOfWeek),
        end_date: weekObject.formatDate(newEndOfThisWeek),
      })
        .then(() => {
          setBeginningOfWeek(newBeginningOfWeek)
          setEndOfWeek(newEndOfThisWeek)
        })
        .catch((error) => {
          console.error(error)
        })
    }
    const employeeList: List[] = [
      { name: translations.home, icon: MdHome, url: ["/employee/home"] },
      {
        name: translations.schedule,
        icon: IoMdCalendar,
        url: [
          "/employee/calendar/days",
          "/employee/calendar/weeks",
          "/employee/calendar/months",
          "/employee/calendar/new",
          "/employee/calendar/layouts",
          `/employee/calendar/edit/${employeeUrlParams.id}`,
        ],
      },
      {
        name: translations.workplace,
        icon: MdChairAlt,
        url: ["/employee/layouts", "/employee/layouts/status"],
      },
      // { name: "ウェルネスサーベイ", image: "survey", url: "#" },
      {
        name: translations.settings,
        icon: MdSettings,
        url: [
          "/employee/settings",
          "/employee/settings/setup-mfa",
          "/employee/settings/external-system-link",
        ],
      },
    ]
    // ホーム画面に遷移した際に今日の日付を取得する
    useEffect(() => {
      if (location.pathname.match("/employee/home")) {
        const today = new Date()
        window.localStorage.setItem("targetDay", today.getTime().toString())
        thisWeekSchedule()
      }
    }, [location.pathname])

    return (
      <>
        {employeeList.map((list, id) => {
          const handleClick = () => {
            if (list.name === translations.home) {
              thisWeekSchedule()
              navigate(list.url[0])
            } else {
              navigate(list.url[0])
            }
          }
          const ListIconComponent = list.url.includes(location.pathname)
            ? SelectListItemIcon
            : CustomListItemIcon
          const IconComponent = list.icon

          return (
            <CustomListItemButton key={id} onClick={handleClick}>
              <CustomBox className="custom-box">{list.name}</CustomBox>
              <ListIconComponent
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                {IconComponent && <IconComponent size={24} color="#FFFFFF" />}
                <IconText>{list.name}</IconText>
              </ListIconComponent>
            </CustomListItemButton>
          )
        })}
      </>
    )
  }
)
