import React, { useState } from "react"
import { IoMdCalendar } from "react-icons/io"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"

import { Box, Button, IconButton, Typography } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

// 日付関連のユーティリティ関数
export const useDateUtilities = () => {
  const getDayOfWeek = (date: Date) => {
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
    return dayOfWeek[date.getDay()]
  }

  const formatDate = (date: Date) => {
    return `${date.getMonth() + 1}月${date.getDate()}日 (${getDayOfWeek(date)})`
  }

  return { getDayOfWeek, formatDate }
}

// コンポーネントのプロップス型定義
interface MobileHomeDateProps {
  currentDate: Date
  onDateChange: (date: Date) => void
}

export const MobileHomeDate: React.FC<MobileHomeDateProps> = ({
  currentDate,
  onDateChange,
}) => {
  const { formatDate } = useDateUtilities()
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  const handlePreviousDay = () => {
    const previousDay = new Date(currentDate)
    previousDay.setDate(previousDay.getDate() - 1)
    onDateChange(previousDay)
  }

  const handleNextDay = () => {
    const nextDay = new Date(currentDate)
    nextDay.setDate(nextDay.getDate() + 1)
    onDateChange(nextDay)
  }

  const handleTodayClick = () => {
    onDateChange(new Date())
  }

  const handleCalendarOpen = () => {
    setIsCalendarOpen(true)
  }

  const handleCalendarClose = () => {
    setIsCalendarOpen(false)
  }

  const handleDateChange = (date: Date | null) => {
    if (date) {
      onDateChange(date)
      setIsCalendarOpen(false)
    }
  }

  return (
    <Box
      sx={{
        height: "56px",
        padding: "10px",
        margin: "0",
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        backgroundColor: "#DDF3EF",
      }}
    >
      <Button
        variant="outlined"
        onClick={handleTodayClick}
        style={{ marginRight: "10px" }}
      >
        今日
      </Button>
      <IconButton onClick={handlePreviousDay}>
        <MdChevronLeft color="22ba9d" />
      </IconButton>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DesktopDatePicker
          open={isCalendarOpen}
          value={currentDate}
          onChange={(newDate) => handleDateChange(newDate)}
          onClose={handleCalendarClose}
          slots={{
            textField: () => (
              <Typography
                variant="h6"
                onClick={handleCalendarOpen}
                sx={{ cursor: "pointer" }}
              >
                {formatDate(currentDate)}
              </Typography>
            ),
          }}
          slotProps={{
            popper: {
              sx: { marginTop: "50px" },
            },
          }}
        />
      </LocalizationProvider>
      <IconButton onClick={handleNextDay}>
        <MdChevronRight color="22ba9d" />
      </IconButton>
      <IconButton onClick={handleCalendarOpen}>
        <IoMdCalendar color="22ba9d" />
      </IconButton>
    </Box>
  )
}
