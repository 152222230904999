import { ja } from "date-fns/locale"

import React, { useEffect, useState, useContext } from "react"
import { IoMdCalendar } from "react-icons/io"
import { MdChevronLeft, MdChevronRight } from "react-icons/md"
import { useLocation, useNavigate } from "react-router-dom"

import { Box, Button, IconButton, styled, Typography } from "@mui/material"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import { api } from "../../api/config/axiosConfig"
// import { EmployeeHomeCheckInOut } from "../../components/employee/home/EmployeeHomeCheckInOut"
import { EmployeeHomeLayout } from "../../components/employee/home/EmployeeHomeLayout"
// import { EmployeeHomeMessages } from "../../components/employee/home/EmployeeHomeMessages"
import { EmployeeHomeSchedule } from "../../components/employee/home/EmployeeHomeSchedule"
import { AlertContent } from "../../components/public/AlertContent"
import { useLanguage } from "../../contexts/LanguageContext"
import { usePasswordExpireMonthes } from "../../models/company/usePasswordExpireMonthes"
import { useAccounts } from "../../models/employee/useAccounts"
import { SchedulableMaxDateContext } from "../../providers/SchedulableMaxDateProvider"
import enTranslations from "../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../translations/employeeHome/employeeHomeTitle/ja"
import { pageType } from "../../utils/location"

const CustomFooter = styled(Box)({
  height: "20px",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
  paddingRight: "20px",
})

const CustomTypography = styled(Typography)({
  fontSize: "10px",
  fontFamily: "Noto Sans",
  lineHeight: "20px",
})

export type LocationParams = {
  Token: Token
  navigateFrom: string
  scheduleDate?: {
    scheduleStartDate: string
  }
}

type Token = {
  m_token: string
  m_refresh_token: string
  m_expires_at: number
  m_user_name: string
  m_user_email: string
  m_user_timezone: string
  g_token: string
  g_refresh_token: string
  g_expires_at: number
  g_user_name: string
  g_user_email: string
  g_user_timezone: string
}
// employee/home全体
export const EmployeeHomePage = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const location = useLocation()
  const token = (location.state as LocationParams)?.Token
  const navigate = useNavigate()
  const { fetchPasswordChangeTimes } = useAccounts()
  const { fetchPasswordExpireMonthes } = usePasswordExpireMonthes()

  useEffect(() => {
    localStorage.removeItem("login")
    if (
      localStorage.getItem("targetDay") == "NaN" ||
      localStorage.getItem("targetDay") == null
    ) {
      localStorage.setItem("targetDay", new Date().getTime().toString())
    }
    fetchPasswordChangeTimes().then((data) => {
      if (data) {
        if (new Date() > new Date(data.password_change_times)) {
          localStorage.setItem("expiredChangePassword", "true")
          navigate(`/${pageType}/password-change`) // 有効期限が切れていた場合パスワード変更画面へ
        }
      }
    })
    fetchPasswordExpireMonthes()
  }, [])

  useEffect(() => {
    if (token) {
      if (token.m_token) {
        api.post("/auth", {
          m_token: token.m_token,
          m_refresh_token: token.m_refresh_token,
          m_expires_at: token.m_expires_at,
          m_user_name: token.m_user_name,
          m_user_email: token.m_user_email,
          m_user_timezone: token.m_user_timezone
            ? token.m_user_timezone
            : "Tokyo Standard Time",
          pathname: window.location.pathname,
        })
      } else {
        api.post("/auth", {
          g_token: token.g_token,
          g_refresh_token: token.g_refresh_token,
          g_expires_at: token.g_expires_at,
          g_user_name: token.g_user_name,
          g_user_email: token.g_user_email,
          g_user_timezone: token.g_user_timezone,
          pathname: window.location.pathname,
        })
      }
    }
  }, [])

  // 曜日を日本語で取得する関数
  const getDayOfWeek = (date: Date) => {
    const dayOfWeek = ["日", "月", "火", "水", "木", "金", "土"]
    return dayOfWeek[date.getDay()]
  }

  // 日付の状態を管理するステート
  const [currentDate, setCurrentDate] = useState<Date>(new Date())

  // 日付のフォーマット関数
  const formatDate = (date: Date) => {
    return `${date.getMonth() + 1}月${date.getDate()}日 (${getDayOfWeek(date)})`
  }

  // 前の日に変更する関数
  const handlePreviousDay = () => {
    const previousDay = new Date(currentDate)
    previousDay.setDate(previousDay.getDate() - 1)
    setCurrentDate(previousDay)
  }

  // 次の日に変更する関数
  const handleNextDay = () => {
    const nextDay = new Date(currentDate)
    nextDay.setDate(nextDay.getDate() + 1)
    setCurrentDate(nextDay)
  }

  // 今日の日付に戻る関数
  const handleTodayClick = () => {
    setCurrentDate(new Date())
  }

  // カレンダーの表示の状態管理するステート
  const [isCalendarOpen, setIsCalendarOpen] = useState(false)

  // カレンダーを開く関数
  const handleCalendarOpen = () => {
    setIsCalendarOpen(true)
  }

  // カレンダーを閉じる関数
  const handleCalendarClose = () => {
    setIsCalendarOpen(false)
  }

  // カレンダーで日付が選択された時の関数
  const handleDateChange = (date: Date | null) => {
    if (date) {
      setCurrentDate(date)
      setIsCalendarOpen(false)
    }
  }

  // 年が変更されたときモーダル保持
  const handleYearChange = (newYear: Date) => {
    setCurrentDate(newYear)
    setIsCalendarOpen(true)
  }

  // 月が変更されたときモーダル保持
  const handleMonthChange = (newMonth: Date) => {
    setCurrentDate(newMonth)
    setIsCalendarOpen(true)
  }

  const { schedulableMaxDate } = useContext(SchedulableMaxDateContext)

  return (
    <>
      <Box
        height="56px"
        padding="16px"
        flexGrow="1"
        display="flex"
        alignItems="center"
        margin="16px 16px 0 16px"
        sx={{ backgroundColor: "#DDF3EF" }}
      >
        <Button
          variant="outlined"
          onClick={handleTodayClick}
          style={{ marginRight: "10px" }}
        >
          今日
        </Button>
        <IconButton onClick={handlePreviousDay}>
          <MdChevronLeft color="22ba9d" />
        </IconButton>
        <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={ja}>
          <DesktopDatePicker
            open={isCalendarOpen}
            value={currentDate}
            onChange={(newDate) => handleDateChange(newDate)}
            onYearChange={handleYearChange}
            onMonthChange={handleMonthChange}
            onClose={handleCalendarClose}
            maxDate={schedulableMaxDate}
            slots={{
              textField: () => (
                <Typography
                  variant="h6"
                  onClick={handleCalendarOpen}
                  sx={{ cursor: "pointer" }}
                >
                  {formatDate(currentDate)}
                </Typography>
              ),
            }}
            slotProps={{
              popper: {
                sx: { marginTop: "50px" },
              },
            }}
          />
        </LocalizationProvider>
        <IconButton onClick={handleNextDay}>
          <MdChevronRight color="22ba9d" />
        </IconButton>
        <IconButton onClick={handleCalendarOpen}>
          <IoMdCalendar color="22ba9d" />
        </IconButton>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        height="calc(100vh - 50px)"
        width="100%"
      >
        <Box display="flex" padding="16px" flexGrow="1">
          <Box display="flex" flexDirection="column" height="100%">
            {/* 2025/1/9 チェックイン機能をコメントアウト */}
            {/* <Box height="145px">
              <EmployeeHomeCheckInOut />
            </Box> */}
            <Box flexGrow="1" width="274px">
              <EmployeeHomeSchedule />
            </Box>
          </Box>
          <Box
            flexGrow="1"
            display="flex"
            flexDirection="column"
            margin="0 0 0 15px"
            height="100%"
            width="calc(100% - 290px)"
          >
            <Box flexGrow="1">
              <EmployeeHomeLayout currentDate={currentDate} />
            </Box>
            {/* 2025/1/9 メッセージを表示していた箇所をコメントアウト */}
            {/* <Box><EmployeeHomeMessages /></Box> */}
          </Box>
        </Box>
        <CustomFooter>
          <CustomTypography variant="overline">
            <a
              href="https://workagile.jp/index.php/terms/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#707070",
              }}
            >
              {translations.TermsService}{" "}
            </a>
            <a
              href="https://workagile.jp/index.php/privacy/"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#707070",
              }}
            >
              {translations.PrivacyPolicy}
            </a>
          </CustomTypography>
        </CustomFooter>
      </Box>

      <AlertContent />
    </>
  )
}
