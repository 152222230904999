import React, { useState } from "react"

import { Tabs, Tab } from "@mui/material"

import { MobileHomeDate } from "../../../components/mobile/home/main/MobileHomeDate"
import { MobileHomeReserve } from "../../../components/mobile/home/main/MobileHomeReserve"
import { MobileHomeSchedule } from "../../../components/mobile/home/main/MobileHomeSchedule"
import { AlertContent } from "../../../components/public/AlertContent"

export const MobileHomePage = () => {
  // 日付の状態を管理するステート
  const [currentDate, setCurrentDate] = useState<Date>(new Date())
  // タブの状態を管理するステート
  const [selectedTab, setSelectedTab] = useState(0)

  // タブがクリックされた時の関数
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue)
  }

  return (
    <>
      <MobileHomeDate currentDate={currentDate} onDateChange={setCurrentDate} />
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        variant="fullWidth"
        sx={{
          "& .MuiTab-root": {
            border: "1px solid #DDF3EF",
            borderRadius: "10px",
            margin: "10px",
          },
          "& .MuiTabs-indicator": {
            display: "none",
          },
          "& .Mui-selected": {
            backgroundColor: "#22BA9D14",
          },
        }}
      >
        <Tab label="座席予約" />
        <Tab label="予約状況/出社情報" />
      </Tabs>
      {selectedTab === 0 && <MobileHomeReserve selectedDate={currentDate} />}
      {selectedTab === 1 && <MobileHomeSchedule selectedDate={currentDate} />}

      <AlertContent />
    </>
  )
}
