import {
  TransformWrapper,
  TransformComponent,
  ReactZoomPanPinchRef,
} from "@pronestor/react-zoom-pan-pinch"

import React, { useRef, useState, useEffect } from "react"

import { Box, styled } from "@mui/material"

import {
  AreaType,
  MeetingRoomType,
  LayoutType,
} from "../../../models/employee/useLayout/type"
import { SeatObjectType } from "../../../models/employee/useLayout/type"
import { EmployeeHomeLayoutArea } from "./EmployeeHomeLayoutArea"
import { EmployeeHomeLayoutMeetingRoom } from "./EmployeeHomeLayoutMeetingRoom"
import { EmployeeHomeLayoutSeat } from "./EmployeeHomeLayoutSeat"

const TransformContainer = styled(Box)(() => ({
  height: "100%",
  overflow: "auto",
}))

interface Props {
  layoutImg: string
  layout: LayoutType
  seats: SeatObjectType[]
  meetingRooms: MeetingRoomType[]
  areas: AreaType[]
  companyRelations: any
  branchId: number
  floorId: number
}

// レイアウト表示部分
export const EmployeeHomeLayoutContentComponent: React.FC<Props> = ({
  layoutImg,
  layout,
  seats,
  meetingRooms,
  areas,
  companyRelations,
  branchId,
  floorId,
}: Props) => {
  const layoutImageRef = useRef<ReactZoomPanPinchRef>(null)
  const [posX, setX] = useState<number>(layout.x)
  const [posY, setY] = useState<number>(layout.y)
  const [layoutScale, setScale] = useState<number>(layout.scale)

  useEffect(() => {
    setX(layout.x)
    setY(layout.y)
    setScale(layout.scale)
  }, [layout.x, layout.y, layout.scale])

  useEffect(() => {
    layoutImageRef.current?.setTransform(posX, posY, layoutScale)
  }, [posX, posY, layoutScale])

  return (
    <>
      <TransformContainer>
        <TransformWrapper
          disabled={false}
          minScale={0.1}
          maxScale={1.0}
          wheel={{ disabled: false, step: 0.05 }}
          panning={{
            disabled: false,
          }}
          initialPositionX={layout.x}
          initialPositionY={layout.y}
          initialScale={layout.scale}
          ref={layoutImageRef}
        >
          <TransformComponent
            wrapperStyle={{ maxWidth: "100%", maxHeight: "100%" }}
          >
            <img id="layout-image" src={layoutImg} />
            {seats.map((seat) => (
              <EmployeeHomeLayoutSeat
                key={`seat${seat.id}`}
                seat={seat}
                companyRelations={companyRelations}
                branchId={branchId}
                floorId={floorId}
              />
            ))}
            {meetingRooms.map((meetingRoom) => {
              return (
                <EmployeeHomeLayoutMeetingRoom
                  key={`meetingRoom${meetingRoom.id}`}
                  meetingRoom={meetingRoom}
                />
              )
            })}
            {areas.map((area) => {
              return [
                <EmployeeHomeLayoutArea key={`area${area.id}`} area={area} />,
                ...(area.children ?? []).map((seat: SeatObjectType) => (
                  <EmployeeHomeLayoutSeat
                    key={`seat${seat.id}`}
                    seat={seat}
                    companyRelations={companyRelations}
                    branchId={branchId}
                    floorId={floorId}
                  />
                )),
              ]
            })}
          </TransformComponent>
        </TransformWrapper>
      </TransformContainer>
    </>
  )
}
