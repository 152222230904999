import React from "react"

import { Box, Button, Modal, Typography } from "@mui/material"

import { api } from "../../../api/config/axiosConfig"
import { useLanguage } from "../../../contexts/LanguageContext"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsIntegrations/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsIntegrations/ja"

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "20px",
  border: "1px solid #707070",
  boxShadow: 24,
  p: 4,
  overflow: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
}

type TokenCheck = {
  m_company_token: boolean
  m_login_user_token: boolean
  g_company_token: boolean
  g_login_user_token: boolean
}

interface ExternalSystemLinkProps {
  selectedItem: string
  onCloseModal: () => void
  tokenCheck: TokenCheck
  setTokenCheck: React.Dispatch<React.SetStateAction<TokenCheck>>
}

export const ExternalSystemLinkComponent: React.FC<ExternalSystemLinkProps> = ({
  selectedItem,
  onCloseModal,
  tokenCheck,
  setTokenCheck,
}) => {
  const externalItems = ["MICROSOFT", "GOOGLE"]
  return (
    <>
      {externalItems.map((item) => (
        <LinkComponent
          key={item}
          item={item}
          tokenCheck={tokenCheck}
          setTokenCheck={setTokenCheck}
          selectedItem={selectedItem}
          onCloseModal={onCloseModal}
        />
      ))}
    </>
  )
}

interface Props {
  item: string
  selectedItem: string
  tokenCheck: TokenCheck
  setTokenCheck: React.Dispatch<React.SetStateAction<TokenCheck>>
  onCloseModal: () => void
}
const LinkComponent: React.FC<Props> = ({
  item,
  selectedItem,
  tokenCheck,
  setTokenCheck,
  onCloseModal,
}) => {
  const { language } = useLanguage()
  const currentTranslations =
    language === "en" ? enTranslations : jaTranslations

  switch (item) {
    case "MICROSOFT":
      return tokenCheck.m_login_user_token ? (
        <>
          {/* outlookで接続中の時は解除モーダル表示 */}
          <Modal open={item === selectedItem} onClose={onCloseModal}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.CancelOutlook}
                </Typography>
                <Typography>{currentTranslations.UnlinkFromOutlook}</Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={onCloseModal} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    textTransform: "none",
                  }}
                  type="submit"
                  onClick={() => {
                    api
                      .delete("/auth/ms_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => onCloseModal())
                      )
                  }}
                >
                  {currentTranslations.Disconnect}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          {/* outlookで未接続の時は接続モーダルを表示 */}
          <Modal open={item === selectedItem} onClose={onCloseModal}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.ConnectOutLook}
                </Typography>
                <Typography>
                  {currentTranslations.ConnectMicrosoftAccount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={onCloseModal} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/microsoft_graph_auth?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      textTransform: "none",
                    }}
                    type="submit"
                  >
                    {currentTranslations.Connect}
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    case "GOOGLE":
      return tokenCheck.g_login_user_token ? (
        <>
          {/* googleで接続中の時は解除モーダル表示 */}
          <Modal open={item === selectedItem} onClose={onCloseModal}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.DisconnectGoogle}
                </Typography>
                <Typography>
                  {currentTranslations.DisconnectGoogleCalendar}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={onCloseModal} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <Button
                  sx={{
                    border: "1px solid",
                    textTransform: "none",
                    fontFamily: "Noto Sans",
                  }}
                  onClick={() => {
                    api
                      .delete("/auth/google_delete", {
                        params: {
                          pathname: window.location.pathname,
                        },
                      })
                      .then(() =>
                        api
                          .get("/auth/confirm")
                          .then((response) => {
                            setTokenCheck(response.data)
                          })
                          .then(() => onCloseModal())
                      )
                  }}
                  type="submit"
                >
                  {currentTranslations.Disconnect}
                </Button>
              </Box>
            </Box>
          </Modal>
        </>
      ) : (
        <>
          {/* googleで未接続の時は接続モーダルを表示 */}
          <Modal open={item === selectedItem} onClose={onCloseModal}>
            <Box sx={style} alignItems="center">
              <Box>
                <Typography
                  component="h1"
                  fontSize="1.5rem"
                  fontWeight="bold"
                  fontFamily="Noto Sans"
                  marginBottom={2}
                >
                  {currentTranslations.ConnectGoogle}
                </Typography>
                <Typography>
                  {currentTranslations.ConnectGoogleAccount}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="end" marginTop={2}>
                <Button onClick={onCloseModal} sx={{ textTransform: "none" }}>
                  {currentTranslations.Cancel}
                </Button>
                <form
                  method="post"
                  name="form1"
                  action={`${process.env.REACT_APP_API_HOST}/auth/google_oauth2?${window.location.pathname}`}
                >
                  <Button
                    sx={{
                      border: "1px solid",
                      textTransform: "none",
                    }}
                    type="submit"
                  >
                    {currentTranslations.Connect}
                  </Button>
                </form>
              </Box>
            </Box>
          </Modal>
        </>
      )
    default:
      return <Box></Box>
  }
}
