import React, { useEffect, useState } from "react"
import { MdRefresh } from "react-icons/md"

import {
  Box,
  styled,
  Typography,
  Select,
  MenuItem,
  FormControl,
  IconButton,
  Button,
} from "@mui/material"
import { TimePicker } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"

import { useLanguage } from "../../../contexts/LanguageContext"
import { useLayout } from "../../../models/employee/useLayout"
import { EmployeeLayoutRequestType } from "../../../models/employee/useLayout/type"
import enTranslations from "../../../translations/employeeHome/employeeHomeTitle/en"
import jaTranslations from "../../../translations/employeeHome/employeeHomeTitle/ja"
import { EmployeeHomeContent } from "./EmployeeHomeContent"
import { EmployeeHomeLayoutContentComponent } from "./EmployeeHomeLayoutContentComponent"

////////// Title

const EmployeeHomeLayoutTitleStyle = styled("span")({
  fontSize: "18px",
  fontWeight: "bold",
  fontFamily: "Noto Sans",
  lineHeight: "24px",
  margin: "0 0 8px 0",
})

const EmployeeHomeLayoutTitle: React.FC = () => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  return (
    <EmployeeHomeLayoutTitleStyle>
      {translations.seatReserve}
    </EmployeeHomeLayoutTitleStyle>
  )
}

interface Props {
  currentDate: Date
}

////////// Content
const EmployeeHomeLayoutContent: React.FC<Props> = ({ currentDate }: Props) => {
  const { language } = useLanguage()
  const translations = language === "en" ? enTranslations : jaTranslations
  const {
    branchId,
    floorId,
    selectedBranch,
    changeSelectValue,
    formatDate,
    layoutImg,
    layout,
    seats,
    meetingRooms,
    areas,
    companyRelations,
    fetchLayout,
    filterHomeSeatSchedules,
    filterHomeMeetingRoomsSchedules,
  } = useLayout()

  // 有効なレイアウトを持つフロアのみをフィルタリングする関数
  const getAvailableFloors = (floors: any[]) => {
    // 日付を計算
    const tomorrow = new Date()
    tomorrow.setHours(0, 0, 0, 0)
    tomorrow.setDate(tomorrow.getDate() + 1)

    return floors.filter((floor) => {
      return floor.layouts?.some(
        (layout: any) =>
          layout.post_status === "released" &&
          new Date(layout.release_date) < tomorrow
      )
    })
  }

  // branchのFormControlのスタイル
  const StyledFormControlBranch = styled(FormControl)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "0.8rem",
    padding: "8px",
  })

  // floorのFormControlのスタイル
  const StyledFormControlFloor = styled(FormControl)({
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    fontSize: "0.8rem",
    marginRight: "32px",
  })

  const [startTime, setStartTime] = useState<Date | null>(null)
  const [endTime, setEndTime] = useState<Date | null>(null)
  // 開始時間、終了時間を更新
  const handleTimeChange = (newValue: Date | null, isStart: boolean) => {
    if (newValue) {
      if (isStart) setStartTime(newValue)
      else setEndTime(newValue)
    }
  }

  // fetchLayoutでレイアウト更新
  const handleLayoutChange = async (params: EmployeeLayoutRequestType) => {
    await fetchLayout(params, companyRelations.id)
  }

  // フロア、日付を切り替えた時のレイアウト更新
  useEffect(() => {
    if (floorId > 0) {
      setStartTime(null)
      setEndTime(null)
      handleLayoutChange({
        branch_id: branchId,
        floor_id: floorId,
        start_date: formatDate(currentDate),
        end_date: formatDate(currentDate),
      })
    }
  }, [floorId, currentDate])

  // 時間が変更された時のレイアウト更新
  useEffect(() => {
    if (startTime || endTime) {
      const formattedDate = formatDate(currentDate)
      const formattedStartTime = startTime
        ? startTime.toLocaleTimeString("ja-JP", { hour12: false })
        : ""
      const formattedEndTime = endTime
        ? endTime.toLocaleTimeString("ja-JP", { hour12: false })
        : ""

      filterHomeSeatSchedules(
        formattedDate,
        formattedStartTime,
        formattedEndTime
      )
      filterHomeMeetingRoomsSchedules(
        formattedDate,
        formattedStartTime,
        formattedEndTime
      )
    }
  }, [startTime, endTime])

  const timeReset = () => {
    setStartTime(null)
    setEndTime(null)
  }

  return (
    <Box>
      <Box
        sx={{
          width: "100%", // 親要素の幅全体に
          borderBottom: "1px solid #BFBFBF",
        }}
      >
        <Box mb={2} display="flex" alignItems="center">
          <StyledFormControlBranch>
            <Select
              name="branch_id"
              value={branchId}
              onChange={changeSelectValue}
              displayEmpty
              sx={{
                width: "160px",
                height: "40px",
                backgroundColor: "#006DFF0A",
              }}
            >
              {companyRelations.branches.map((branch) => (
                <MenuItem key={branch.id} value={branch.id}>
                  {branch.branch_name}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControlBranch>
          <StyledFormControlFloor>
            <Select
              name="floor_id"
              value={floorId}
              onChange={changeSelectValue}
              displayEmpty
              disabled={!branchId} // 拠点が選択されていない場合は無効化
              sx={{
                width: "120px",
                height: "40px",
                backgroundColor: "#006DFF0A",
              }}
            >
              {selectedBranch?.floors
                .filter((floor) => getAvailableFloors([floor]).length > 0) // 公開中フロアのみ表示
                .map((floor) => (
                  <MenuItem key={floor.id} value={floor.id}>
                    {floor.floor_name}
                  </MenuItem>
                ))}
            </Select>
          </StyledFormControlFloor>
          {/* リセットボタン */}
          {/* 追加対応:デフォルト時間をセットする */}
          <IconButton sx={{ width: "40px" }} onClick={timeReset}>
            <MdRefresh color="#22BA9D" />
          </IconButton>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <Box display="flex" alignItems="center" gap={1}>
              <TimePicker
                value={startTime}
                ampm={false}
                timeSteps={{ minutes: 15 }}
                onChange={(newValue) => handleTimeChange(newValue, true)}
                sx={{
                  width: "100px",
                  backgroundColor: "#006DFF0A",
                  "& .MuiInputBase-root": { height: "40px" },
                }}
                slotProps={{
                  textField: {
                    inputProps: { readOnly: true, style: { fontSize: "14px" } },
                  },
                }}
              />
              <Typography sx={{ fontSize: "16px", margin: "8px 0" }}>
                〜
              </Typography>
              <TimePicker
                value={endTime}
                ampm={false}
                onChange={(newValue) => handleTimeChange(newValue, false)}
                sx={{
                  width: "100px",
                  backgroundColor: "#006DFF0A",
                  "& .MuiInputBase-root": { height: "40px" },
                }}
                slotProps={{
                  textField: {
                    inputProps: { readOnly: true, style: { fontSize: "14px" } },
                  },
                }}
              />
            </Box>
          </LocalizationProvider>
          <Button
            // 追加対応:onchange追加
            sx={{
              border: "1px solid #22BA9D",
              height: "40px",
              marginLeft: "auto",
            }}
          >
            ランダム座席選択
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: "1000px",
          height: "500px",
          flexGrow: 1,
          paddingTop: "8px",
        }}
      >
        {layoutImg && seats.length > 0 ? (
          <EmployeeHomeLayoutContentComponent
            seats={seats}
            meetingRooms={meetingRooms}
            areas={areas}
            layoutImg={layoutImg}
            layout={layout}
            companyRelations={companyRelations}
            branchId={branchId}
            floorId={floorId}
          />
        ) : (
          <Typography align="center" fontSize="12px">
            {translations.LayoutNot}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

interface EmployeeHomeLayoutProps {
  currentDate: Date
}

export const EmployeeHomeLayout: React.FC<EmployeeHomeLayoutProps> = ({
  currentDate,
}) => {
  return (
    <EmployeeHomeContent
      title={<EmployeeHomeLayoutTitle />}
      content={<EmployeeHomeLayoutContent currentDate={currentDate} />}
      areaName="Layout"
    />
  )
}
