import { api, ApiResponse } from "../../config/axiosConfig"

// スケジュールチェックアウトのリクエストインターフェース
interface ScheduleCheckOutRequestParams {
  scheduleId: number
}

// スケジュールチェックアウトのレスポンス型を定義
interface ScheduleCheckOutResponse {
  // レスポンスの具体的な構造に応じて適切なプロパティを追加
  success: boolean
  message: string
}

// スケジュールチェックアウトAPI関数
export const scheduleCheckOutRequest = async (
  params: ScheduleCheckOutRequestParams
): Promise<ApiResponse<ScheduleCheckOutResponse>> => {
  try {
    const response = await api.post<ScheduleCheckOutResponse>(
      "/mobile/home/schedule_check_out",
      {
        schedule_id: params.scheduleId,
      }
    )
    return {
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error("スケジュールチェックアウトエラー", error)
    throw error
  }
}
