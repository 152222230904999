import React, { memo, useEffect, useState } from "react"

import { Box, Modal, Tooltip, Typography } from "@mui/material"

import {
  checkInRequest,
  fetchCheckInStatusRequest,
} from "../../../api/employee/scheduleRequest"
import { useLanguage } from "../../../contexts/LanguageContext"
import { SeatObjectType } from "../../../models/employee/useLayout/type"
import { CheckInData } from "../../../models/employee/useSchedule/type"
import {
  freeSeatStyle,
  notCheckInSeatStyle,
  unavailableStyle,
  checkInSeatStyle,
} from "../../../styles/seatStyle"
import enTranslations from "../../../translations/errorMessage/en"
import jaTranslations from "../../../translations/errorMessage/ja"
import {
  formatDateForSafari,
  formatScheduleTimeRangeForDisplay,
} from "../../../utils/dateTimeFormat"
import { CheckInAlreadyReserved } from "../checkIn/CheckInAlreadyReserved"
import { CheckInCompleted } from "../checkIn/CheckInCompleted"
import { CheckIn } from "../checkIn/CheckInMain"
import { CheckInReservationSeat } from "../checkIn/CheckInReservationSeat"
import { CheckOutMain } from "../checkOut/CheckOutMain"

interface Props {
  seat: SeatObjectType
  companyRelations: any
  branchId: number
  floorId: number
}

/*
  ダッシュボードのレイアウトに表示される席アイコン
*/
export const EmployeeHomeLayoutSeat: React.FC<Props> = memo(
  ({ seat, companyRelations, branchId, floorId }: Props) => {
    // 言語切り替え
    const { language } = useLanguage()
    const translations = language === "en" ? enTranslations : jaTranslations

    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false)
    const [checkInData, setCheckInData] = useState<CheckInData | undefined>()
    const [isCheckInModalOpen, setIsCheckInModalOpen] = useState(false)
    const [isCheckInCompletedModalOpen, setIsCheckInCompletedModalOpen] =
      useState(false)
    const [isCheckOutModalOpen, setIsCheckOutModalOpen] = useState(false)
    const [isAlreadyReservedModalOpen, setIsAlreadyReservedModalOpen] =
      useState(false)
    const [isReservationSeatModalOpen, setIsReservationSeatModalOpen] =
      useState(false)
    const [checkInError, setCheckInError] = useState<boolean>(false)
    const [checkInErrorMessage, setCheckInErrorMessage] = useState<string>("")
    const [isAnyModalOpen, setIsAnyModalOpen] = useState(false)

    // モーダルが開いている場合は handleSeatClickを機能させない
    useEffect(() => {
      const anyModalOpen =
        isCheckInModalOpen ||
        isCheckInCompletedModalOpen ||
        isCheckOutModalOpen ||
        isAlreadyReservedModalOpen ||
        isReservationSeatModalOpen
      setIsAnyModalOpen(anyModalOpen)
    }, [
      isCheckInModalOpen,
      isCheckInCompletedModalOpen,
      isCheckOutModalOpen,
      isAlreadyReservedModalOpen,
      isReservationSeatModalOpen,
    ])

    const branch = companyRelations.branches.find(
      (b: { id: number; floors: { id: number }[] }) => b.id === branchId
    )

    const floor = branch?.floors.find((f: { id: number }) => f.id === floorId)

    // 座席クリック時の操作
    const handleSeatClick = async () => {
      if (isAnyModalOpen) return

      const dataToPass = {
        branchId: branch?.id,
        floorId: floor?.id,
        seatId: seat.id != null ? seat.id : 0,
        branchName: branch?.branch_name,
        floorNumber: floor?.floor_number,
        seatName: seat.seat_name.toString(),
      }
      setCheckInData(dataToPass)

      if (!seat.id) {
        return
      }

      // ユーザーが座席にチェックインしているかどうかの判定
      const checkInStatus = await fetchCheckInStatusRequest({
        reservable_type: "Seat",
        reservable_id: seat.id,
        userable_type: "Employee",
      })
      if (checkInStatus.error) {
        setCheckInError(true)
        setCheckInErrorMessage(
          "チェックイン状態の取得に失敗しました。\n画面を再読み込みしてください"
        )
        return
      }

      // 利用不可の座席かどうかの判定
      if (checkInStatus.data && checkInStatus.data.is_unavailable_seat) {
        return
      }

      // すでにチェックインしている場合（チェックアウトの表示）
      if (checkInStatus.data && checkInStatus.data.check_in_status === true) {
        setIsCheckOutModalOpen(true)
        return
      }

      // 座席の状態の判断
      const seatStatus = await checkInRequest({
        reservable_id: seat.id,
        reservable_type: "Seat",
      })
      if (seatStatus.error) {
        setCheckInError(true)
        setCheckInErrorMessage(translations.NoArea)
      }

      if (seatStatus.data) {
        // チェックイン完了
        if (seatStatus.data.check_in_completed) {
          setIsCheckInCompletedModalOpen(true)
          return
        }
        // 既に他従業員が予約している場合
        if (seatStatus.data.already_reserved) {
          setIsAlreadyReservedModalOpen(true)
          return
        }
        // 指定席の場合
        if (seatStatus.data.is_reservation_seat) {
          setIsReservationSeatModalOpen(true)
          return
        }
        // すでにこの席にチェックインしている場合（チェックアウトモーダル表示）
        if (seatStatus.data.is_checked_in_now === "this_seat") {
          setIsCheckOutModalOpen(true)
          return
        }
      }
      setIsCheckInModalOpen(true)
    }

    const handleInstantCheckInSuccess = () => {
      setIsCheckInModalOpen(false)
      setIsCheckInCompletedModalOpen(true)
    }

    const generateStyleObject = (x: number, y: number) => {
      let objectStyle = undefined
      const seatType = Number(seat.seat_type)
      if (seatType === 2) {
        objectStyle = unavailableStyle
      } else if (!seat.schedule) {
        objectStyle = freeSeatStyle
      } else if (seat.schedule.checked_in) {
        objectStyle = checkInSeatStyle
      } else {
        objectStyle = notCheckInSeatStyle
      }
      return { ...objectStyle, top: y, left: x }
    }

    const onMouseEnter = () => {
      setTooltipOpen(true)
    }

    const onMouseLeave = () => {
      setTooltipOpen(false)
    }

    // モーダルが開いている場合は tooltipを非表示
    useEffect(() => {
      if (
        isCheckInModalOpen ||
        isCheckInCompletedModalOpen ||
        isCheckOutModalOpen ||
        isAlreadyReservedModalOpen ||
        isReservationSeatModalOpen
      ) {
        setTooltipOpen(false)
      }
    }, [
      isCheckInModalOpen,
      isCheckInCompletedModalOpen,
      isCheckOutModalOpen,
      isAlreadyReservedModalOpen,
      isReservationSeatModalOpen,
    ])

    const tooltipText = () => {
      const seatInfo = `座席番号:${seat.seat_name}\n`
      const employeeName = `予約者: ${
        seat.schedule?.userable?.last_name ?? ""
      } ${seat.schedule?.userable?.first_name ?? ""}\n`
      const scheduleTitle =
        seat.schedule?.schedule_type === "status"
          ? ""
          : `件名: ${seat.schedule?.schedule_title}\n`
      const scheduleTime = seat.schedule?.whole_day_flag
        ? "終日"
        : formatScheduleTimeRangeForDisplay(
            formatDateForSafari(seat.schedule?.start_time as string),
            formatDateForSafari(seat.schedule?.end_time as string)
          )

      return `${seatInfo}${employeeName}${scheduleTitle}${scheduleTime}`
    }

    return (
      <Box
        id={String(seat.id)}
        className={"seat"}
        sx={
          generateStyleObject(seat.x, seat.y) as React.CSSProperties | undefined
        }
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onClick={handleSeatClick}
      >
        {checkInData && (
          <Modal
            open={isReservationSeatModalOpen}
            onClose={() => setIsReservationSeatModalOpen(false)}
          >
            <CheckInReservationSeat
              companyRelations={companyRelations}
              checkInData={checkInData}
              selectedBranch={companyRelations.branches.find(
                (b: { id: number }) => b.id === branchId
              )}
              selectedFloor={companyRelations.branches
                .find((b: { id: number }) => b.id === branchId)
                ?.floors.find((f: { id: number }) => f.id === floorId)}
            />
          </Modal>
        )}
        {checkInData && (
          <Modal
            open={isAlreadyReservedModalOpen}
            onClose={() => setIsAlreadyReservedModalOpen(false)}
          >
            <CheckInAlreadyReserved
              companyRelations={companyRelations}
              checkInData={checkInData}
              selectedBranch={companyRelations.branches.find(
                (b: { id: number }) => b.id === branchId
              )}
              selectedFloor={companyRelations.branches
                .find((b: { id: number }) => b.id === branchId)
                ?.floors.find((f: { id: number }) => f.id === floorId)}
            />
          </Modal>
        )}
        {checkInData && (
          <Modal
            open={isCheckOutModalOpen}
            onClose={() => setIsCheckOutModalOpen(false)}
          >
            <CheckOutMain
              companyRelations={companyRelations}
              checkInData={checkInData}
              selectedBranch={companyRelations.branches.find(
                (b: { id: number }) => b.id === branchId
              )}
              selectedFloor={companyRelations.branches
                .find((b: { id: number }) => b.id === branchId)
                ?.floors.find((f: { id: number }) => f.id === floorId)}
            />
          </Modal>
        )}
        {checkInData && (
          <Modal
            open={isCheckInModalOpen}
            onClose={() => setIsCheckInModalOpen(false)}
          >
            <CheckIn
              companyRelations={companyRelations}
              checkInData={checkInData}
              onSuccess={handleInstantCheckInSuccess}
              selectedBranch={companyRelations.branches.find(
                (b: { id: number }) => b.id === branchId
              )}
              selectedFloor={companyRelations.branches
                .find((b: { id: number }) => b.id === branchId)
                ?.floors.find((f: { id: number }) => f.id === floorId)}
            />
          </Modal>
        )}
        {checkInData && (
          <Modal
            open={isCheckInCompletedModalOpen}
            onClose={() => setIsCheckInCompletedModalOpen(false)}
          >
            <CheckInCompleted
              companyRelations={companyRelations}
              checkInData={checkInData}
              selectedBranch={companyRelations.branches.find(
                (b: { id: number }) => b.id === branchId
              )}
              selectedFloor={companyRelations.branches
                .find((b: { id: number }) => b.id === branchId)
                ?.floors.find((f: { id: number }) => f.id === floorId)}
            />
          </Modal>
        )}
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "absolute",
            flexDirection: "column",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {seat.schedule ? (
            <Tooltip
              title={tooltipText()}
              placement="bottom"
              disableInteractive
              open={tooltipOpen}
              componentsProps={{
                tooltip: {
                  sx: {
                    whiteSpace: "pre-wrap",
                    position: "fixed",
                    width: "100px",
                  },
                },
              }}
            >
              {seat.schedule?.image_blob ? (
                <img
                  style={{
                    width: "100%",
                    height: "100%",
                    borderRadius: "50%",
                  }}
                  src={seat.schedule.image_blob}
                />
              ) : (
                <Typography
                  sx={{ fontSize: "20px" }}
                  color="inherit"
                  display="flex"
                >
                  <span>{seat.schedule.userable?.last_name?.[0] ?? ""}</span>
                  <span>{seat.schedule.userable?.first_name?.[0] ?? ""}</span>
                </Typography>
              )}
            </Tooltip>
          ) : (
            seat.seat_name
          )}
        </div>
      </Box>
    )
  }
)
