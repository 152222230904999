import { format } from "date-fns"

import React, { useMemo, useEffect, useState } from "react"
import { MdOutlinePlace, MdChairAlt, MdOutlineAccessTime } from "react-icons/md"

import {
  Box,
  Typography,
  styled,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  CircularProgress,
} from "@mui/material"

import { scheduleCancelRequest } from "../../../../../api/mobile/home/scheduleCancelRequest"
import { scheduleCheckInRequest } from "../../../../../api/mobile/home/scheduleCheckInRequest"
import { scheduleCheckOutRequest } from "../../../../../api/mobile/home/scheduleCheckOutRequest"
import { useScheduleHome } from "../../../../../models/employee/useSchedule/useScheduleHome"

interface MobileHomeScheduleProps {
  selectedDate: Date
}

interface ScheduleHomeType {
  id: number
  reservable?: {
    floor: {
      branch: {
        branch_name: string
      }
      floor_name?: string
    }
    seat_name: string
    meeting_room_name: string
  }
  seat_name?: string
  schedule_type?: string
  schedule_status?: string
  schedule_title?: string
  start_time?: string
  end_time?: string
  scheduled_date?: string
  checked_in?: boolean
  check_in_date?: string
  canceled?: boolean
}

interface ConfirmationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  title: string
  contentText: string
  confirmText?: string
  cancelText?: string
}

// 確認用モーダルのコンポーネント
export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  contentText,
  confirmText = "はい",
  cancelText = "いいえ",
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirmation-dialog-title"
      aria-describedby="confirmation-dialog-description"
    >
      <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="confirmation-dialog-description">
          {contentText}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {confirmText}
        </Button>
        <Button onClick={onClose} color="secondary" autoFocus>
          {cancelText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// スケジュールを表示するカード
const ScheduleItemBox = styled(Box)<{
  isInOffice?: boolean
  isSeat?: boolean
  isCanceled?: boolean
}>(({ theme, isInOffice, isSeat, isCanceled }) => ({
  border: `1px solid ${
    !isCanceled && isSeat ? "#22BA9D" : theme.palette.divider
  }`,
  borderRadius: "4px",
  padding: "8px",
  marginBottom: "8px",
  display: "flex",
  flexDirection: "column",
  backgroundColor: isInOffice ? "inherit" : "#F5F5F5",
}))

// テキストのスタイル
const ScheduleInfoText = styled(Typography)({
  fontSize: "12px",
  marginBottom: "4px",
})

// イスのアイコン
const StyledChairIcon = styled(MdChairAlt)(({ theme }) => ({
  backgroundColor: "#22BA9D",
  color: "#FFFFFF",
  borderRadius: "4px",
  padding: "2px",
}))

// チェックイン・チェックアウトボタン
const CheckInOutButton = styled("button")<{ disabled?: boolean }>(
  ({ theme, disabled }) => ({
    backgroundColor: disabled ? "#CCCCCC" : "#006dff",
    color: "#FFFFFF",
    fontSize: "12px",
    width: "116px",
    height: "36px",
    border: "none",
    borderRadius: "4px",
    cursor: disabled ? "not-allowed" : "pointer",
    transition: "background-color 0.3s ease",
    "&:hover": {
      backgroundColor: disabled ? "#CCCCCC" : "#1EA38C",
    },
  })
)

// 予約取消のテキスト
const CancelReservationText = styled(Typography)(({ theme }) => ({
  color: "#006dff",
  cursor: "pointer",
  fontSize: "12px",
  "&:hover": {
    textDecoration: "underline",
  },
}))

// 図面で表示のテキスト
const DisplayDrawingText = styled(Typography)(({ theme }) => ({
  color: "#0288D1",
  cursor: "pointer",
  fontSize: "12px",
  textDecoration: "underline",
}))

// 自動キャンセルされたスケジュールのテキスト
const CanceledScheduleText = styled(Typography)(({ theme }) => ({
  color: "#333333",
  fontWeight: "bold",
}))

export const MobileHomeSchedule: React.FC<MobileHomeScheduleProps> = ({
  selectedDate,
}) => {
  // 選択された日付を文字列に変換（YYYY-MM-DD形式）
  const formattedSelectedDate = useMemo(
    () => format(selectedDate, "yyyy-MM-dd"),
    [selectedDate.getTime()] // タイムスタンプで比較
  )

  const { mySchedules, fetchMySchedule, loading } = useScheduleHome()

  useEffect(() => {
    const fetchScheduleSafely = async () => {
      try {
        // タイムアウト時は、既存のスケジュールを表示
        await Promise.race([
          fetchMySchedule({
            start_date: formattedSelectedDate,
            end_date: formattedSelectedDate,
          }),
          new Promise((_, reject) =>
            setTimeout(() => reject(new Error("Timeout")), 10000)
          ),
        ])
      } catch (error) {
        // エラー時は既存のスケジュールをそのまま表示
        console.log("スケジュール読み込みに時間がかかっています")
      }
    }

    fetchScheduleSafely()
  }, [selectedDate, fetchMySchedule])

  // チェックイン可能かどうかを判定する関数
  const isCheckInAvailable = (
    startTime: string,
    endTime: string,
    checkedIn: boolean
  ) => {
    if (!startTime || !endTime || checkedIn) return false

    const now = new Date()
    const scheduleStartTime = new Date(startTime)
    const scheduleEndTime = new Date(endTime)

    const timeDifferenceStart = scheduleStartTime.getTime() - now.getTime()
    const timeDifferenceEnd = scheduleEndTime.getTime() - now.getTime()

    // 予約開始時刻の15分以上前は非活性
    return timeDifferenceStart <= 15 * 60 * 1000 && timeDifferenceEnd >= 0
  }

  // チェックアウト可能かどうかを判定する関数
  const isCheckOutAvailable = (
    checkInDate: string,
    endTime: string,
    checkedIn: boolean
  ) => {
    if (!checkInDate || !endTime || !checkedIn) return false

    const now = new Date()
    const checkInDateTime = new Date(checkInDate)

    // 今日の日付より前の日付の場合は非活性
    const isToday = now.toDateString() === checkInDateTime.toDateString()

    return isToday
  }

  // 選択された日付のスケジュールをフィルタリング
  const allSchedules = useMemo(() => {
    return mySchedules
      .flatMap(
        (scheduleDay) =>
          [
            ...scheduleDay.event_schedules,
            ...scheduleDay.status_schedules,
          ] as ScheduleHomeType[]
      )
      .filter(
        (schedule) =>
          schedule.id &&
          // 会議室を削除した際にMicrosoftの予定が残っている可能性があり、その場合は表示しない
          // 課題: 会議室の予約を削除した際にMicrosoftの予定を削除する処理を追加
          typeof schedule.id === "number" &&
          schedule.scheduled_date === formattedSelectedDate
      )
      .sort((a, b) => {
        // チェックイン可能な予定を最優先
        const aCheckInAvailable = isCheckInAvailable(
          a.start_time || "",
          a.end_time || "",
          a.checked_in || false
        )
        const bCheckInAvailable = isCheckInAvailable(
          b.start_time || "",
          b.end_time || "",
          b.checked_in || false
        )

        if (aCheckInAvailable && !bCheckInAvailable) return -1
        if (!aCheckInAvailable && bCheckInAvailable) return 1

        // チェックアウト可能な予定を2番目に優先
        const aCheckOutAvailable = isCheckOutAvailable(
          a.check_in_date || "",
          a.end_time || "",
          a.checked_in || false
        )
        const bCheckOutAvailable = isCheckOutAvailable(
          b.check_in_date || "",
          b.end_time || "",
          b.checked_in || false
        )

        if (aCheckOutAvailable && !bCheckOutAvailable) return -1
        if (!aCheckOutAvailable && bCheckOutAvailable) return 1

        // start_timeが異なる場合は、start_timeで比較
        if (a.start_time && b.start_time) {
          const startTimeDiff =
            new Date(a.start_time).getTime() - new Date(b.start_time).getTime()
          if (startTimeDiff !== 0) return startTimeDiff
        }

        // start_timeが同じ場合、seatを優先
        const aIsSeat = !!a.reservable?.seat_name
        const bIsSeat = !!b.reservable?.seat_name

        // seatを先に表示
        if (aIsSeat && !bIsSeat) return -1
        if (!aIsSeat && bIsSeat) return 1

        // start_timeとseat条件が同じ場合は、元の順序を維持
        return 0
      })
  }, [mySchedules, formattedSelectedDate])

  // モーダル表示用の状態
  const [confirmModal, setConfirmModal] = useState<{
    isOpen: boolean
    scheduleId: number | null
    type: "cancel" | "checkIn" | "checkOut" | null
  }>({
    isOpen: false,
    scheduleId: null,
    type: null,
  })

  // モーダルを開く関数
  const openConfirmModal = (
    scheduleId: number,
    type: "cancel" | "checkIn" | "checkOut"
  ) => {
    setConfirmModal({
      isOpen: true,
      scheduleId: scheduleId,
      type: type,
    })
  }

  // モーダルを閉じる関数
  const closeConfirmModal = () => {
    setConfirmModal({
      isOpen: false,
      scheduleId: null,
      type: null,
    })
  }

  // 予約取消ハンドラー
  const handleCancelSchedule = async () => {
    if (!confirmModal.scheduleId) return

    try {
      const result = await scheduleCancelRequest({
        scheduleId: confirmModal.scheduleId,
      })

      if (result && result.data && result.data.success) {
        // 成功時の処理
        await fetchMySchedule({
          start_date: formattedSelectedDate,
          end_date: formattedSelectedDate,
        })
        closeConfirmModal()
      } else {
        console.error("予約キャンセルに失敗しました")
      }
    } catch (error) {
      console.error("予約キャンセル中にエラーが発生しました", error)
    }
  }

  // チェックインハンドラー
  const handleCheckIn = async () => {
    if (!confirmModal.scheduleId) return

    try {
      const result = await scheduleCheckInRequest({
        scheduleId: confirmModal.scheduleId,
      })

      if (result && result.data && result.data.success) {
        // チェックイン成功時、スケジュールを再読み込み
        await fetchMySchedule({
          start_date: formattedSelectedDate,
          end_date: formattedSelectedDate,
        })
        closeConfirmModal()
      } else {
        // エラー時の処理
        console.error("チェックインに失敗しました")
      }
    } catch (error) {
      console.error("チェックイン中にエラーが発生しました", error)
    }
  }

  // チェックアウトハンドラー
  const handleCheckOut = async () => {
    if (!confirmModal.scheduleId) return

    try {
      // チェックアウトAPIのリクエストを送信
      const result = await scheduleCheckOutRequest({
        scheduleId: confirmModal.scheduleId,
      })

      if (result && result.data && result.data.success) {
        // チェックアウト成功時、スケジュールを再読み込み
        await fetchMySchedule({
          start_date: formattedSelectedDate,
          end_date: formattedSelectedDate,
        })
        closeConfirmModal()
      } else {
        // エラー時の処理
        console.error("チェックアウトに失敗しました")
      }
    } catch (error) {
      console.error("チェックアウト中にエラーが発生しました", error)
    }
  }

  return (
    <Box
      sx={{
        padding: "8px",
        backgroundColor: "#FFFFFF",
        marginLeft: "10px",
        marginRight: "10px",
      }}
    >
      <Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : allSchedules.length > 0 ? (
          allSchedules.map((schedule, index) => (
            <ScheduleItemBox
              key={index}
              isInOffice={schedule.schedule_status === "in_office"}
              isSeat={!!schedule.reservable?.seat_name}
              isCanceled={schedule.canceled}
              sx={{
                backgroundColor: schedule.canceled
                  ? "#F5F5F5"
                  : schedule.schedule_status !== "in_office"
                  ? "#F5F5F5"
                  : "inherit",
                color: schedule.canceled ? "#C5C5C5" : "inherit",
              }}
            >
              {schedule.schedule_status === "in_office" ? (
                <>
                  <ScheduleInfoText>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <MdOutlinePlace />
                      {`${schedule.reservable?.floor.branch.branch_name}    ${schedule.reservable?.floor.floor_name}`}
                    </Box>
                  </ScheduleInfoText>
                  <ScheduleInfoText>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      {schedule.reservable?.seat_name && <StyledChairIcon />}
                      {schedule.reservable?.seat_name
                        ? "#"
                        : schedule.reservable?.meeting_room_name
                        ? "会議室#"
                        : ""}
                      {schedule.reservable?.seat_name ||
                        schedule.reservable?.meeting_room_name}
                      {!schedule.canceled && (
                        <DisplayDrawingText>図面で表示</DisplayDrawingText>
                      )}
                    </Box>
                  </ScheduleInfoText>
                  <ScheduleInfoText>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <MdOutlineAccessTime />
                      {`${schedule.start_time
                        ?.split(" ")[1]
                        .slice(0, 5)} ~ ${schedule.end_time
                        ?.split(" ")[1]
                        .slice(0, 5)}`}
                    </Box>
                  </ScheduleInfoText>
                  {schedule.canceled ? (
                    <CanceledScheduleText
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      自動キャンセルされました
                    </CanceledScheduleText>
                  ) : schedule.checked_in && schedule.check_in_date ? (
                    <ScheduleInfoText
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <CheckInOutButton
                        onClick={() =>
                          openConfirmModal(schedule.id, "checkOut")
                        }
                        disabled={
                          !isCheckOutAvailable(
                            schedule.check_in_date || " ",
                            schedule.end_time || " ",
                            schedule.checked_in || true
                          )
                        }
                      >
                        チェックアウト
                      </CheckInOutButton>
                    </ScheduleInfoText>
                  ) : (
                    <ScheduleInfoText
                      sx={{
                        display: "flex",
                        gap: "8px",
                        alignItems: "center",
                      }}
                    >
                      <CheckInOutButton
                        onClick={() => openConfirmModal(schedule.id, "checkIn")}
                        disabled={
                          !isCheckInAvailable(
                            schedule.start_time || " ",
                            schedule.end_time || " ",
                            schedule.checked_in || false
                          )
                        }
                      >
                        チェックイン
                      </CheckInOutButton>
                      <CancelReservationText
                        onClick={() => openConfirmModal(schedule.id, "cancel")}
                      >
                        予約取消
                      </CancelReservationText>
                    </ScheduleInfoText>
                  )}
                </>
              ) : (
                <>
                  <ScheduleInfoText>{schedule.schedule_title}</ScheduleInfoText>
                  {schedule.start_time && schedule.end_time && (
                    <ScheduleInfoText>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <MdOutlineAccessTime />
                        {`${schedule.start_time
                          .split(" ")[1]
                          ?.slice(0, 5)} ~ ${schedule.end_time
                          .split(" ")[1]
                          ?.slice(0, 5)}`}
                      </Box>
                    </ScheduleInfoText>
                  )}
                </>
              )}
            </ScheduleItemBox>
          ))
        ) : (
          <Typography>選択された日のスケジュールはありません</Typography>
        )}
      </Box>

      {confirmModal.type === "cancel" && (
        <ConfirmationDialog
          // 予約取消確認用のモーダル
          open={confirmModal.isOpen}
          onClose={closeConfirmModal}
          onConfirm={handleCancelSchedule}
          title="予約のキャンセル"
          contentText="この予約を取り消してもよろしいですか？"
        />
      )}
      {confirmModal.type === "checkIn" && (
        <ConfirmationDialog
          // チェックイン確認用のモーダル
          open={confirmModal.isOpen}
          onClose={closeConfirmModal}
          onConfirm={handleCheckIn}
          title="チェックイン"
          contentText="チェックインしてもよろしいですか？"
        />
      )}
      {confirmModal.type === "checkOut" && (
        <ConfirmationDialog
          // チェックアウト確認用のモーダル
          open={confirmModal.isOpen}
          onClose={closeConfirmModal}
          onConfirm={handleCheckOut}
          title="チェックアウト"
          contentText="チェックアウトしてもよろしいですか？"
        />
      )}
    </Box>
  )
}
