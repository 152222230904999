import React from "react"
import { Routes, Route } from "react-router-dom"

import { MeetingRoomCalendar } from "../components/employee/calendar/meetingRooms/Main"
import { CommonTemplate } from "../pages/CommonTemplate"
import { ErrorPage } from "../pages/ErrorPage"
import { EmployeeHomePage } from "../pages/employee"
import { FederatedSignInPage } from "../pages/employee/auth/FederatedSignInPage"
import { EmployeeMFA } from "../pages/employee/auth/MFA"
import { EmployeeConfirmForgotPassword } from "../pages/employee/auth/confirmForgotPassword"
import { EmployeeForgotPassword } from "../pages/employee/auth/forgotPassword"
import { EmployeeForgotPasswordApplied } from "../pages/employee/auth/forgotPasswordApplied"
import { EmployeeLogin } from "../pages/employee/auth/login"
import { EmployeePasswordChange } from "../pages/employee/auth/passwordChange"
import { CalendarOfDaysPage } from "../pages/employee/calendar/days"
import { CalendarEditPage } from "../pages/employee/calendar/edit"
import { EmployeeCalendarLayoutPage } from "../pages/employee/calendar/layouts"
import { CalendarOfMonthsPage } from "../pages/employee/calendar/months"
import { CalendarNew } from "../pages/employee/calendar/new"
import { CalendarOfWeeksPage } from "../pages/employee/calendar/weeks"
import { EmployeeLayoutPage } from "../pages/employee/layouts"
import { EmployeeStatusPage } from "../pages/employee/layouts/status"
import { EmployeeProfileShowPage } from "../pages/employee/profile/show"
import { EmployeeSettingsPage } from "../pages/employee/settings"
import { SchedulableMaxDateProvider } from "../providers/SchedulableMaxDateProvider"
import { EmployeePrivateRoute } from "./EmployeePrivateRoute"
import { EmployeePublicRoute } from "./EmployeePublicRoute"

export const EmployeeRouter: React.FC = () => {
  return (
    <>
      <Routes>
        {/* <Route path="" element={<EmployeeHome />} /> */}
        <Route
          path="/calendar/days"
          element={
            <EmployeePrivateRoute
              path="/calendar/days"
              element={
                <CommonTemplate>
                  <CalendarOfDaysPage />
                </CommonTemplate>
              }
            ></EmployeePrivateRoute>
          }
        />
        <Route
          path="/calendar/new"
          element={
            <EmployeePrivateRoute
              path="/calendar/new"
              element={
                <CommonTemplate>
                  <CalendarNew />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/calendar/edit/:id"
          element={
            <EmployeePrivateRoute
              path="/calendar/edit/:id"
              element={
                <CommonTemplate>
                  <CalendarEditPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/home"
          element={
            <EmployeePrivateRoute
              path="/home"
              element={
                <CommonTemplate>
                  <SchedulableMaxDateProvider>
                    <EmployeeHomePage />
                  </SchedulableMaxDateProvider>
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/layouts"
          element={
            <EmployeePrivateRoute
              path="/layouts"
              element={
                <CommonTemplate>
                  <EmployeeLayoutPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/layouts/status"
          element={
            <EmployeePrivateRoute
              path="/layouts/status"
              element={
                <CommonTemplate>
                  <EmployeeStatusPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="calendar/layouts"
          element={
            <EmployeePrivateRoute
              path="/calendar/layouts"
              element={
                <CommonTemplate>
                  <EmployeeCalendarLayoutPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route path="/federated-sign-in" element={<FederatedSignInPage />} />
        <Route
          path="/login"
          element={
            <EmployeePublicRoute path="/login" element={<EmployeeLogin />} />
          }
        />
        <Route
          path="/mfa"
          element={
            <EmployeePublicRoute path="/login" element={<EmployeeMFA />} />
          }
        />
        <Route path="/password-change" element={<EmployeePasswordChange />} />
        <Route
          path="/forgot-password"
          element={
            <EmployeePublicRoute
              path="/forgot-password"
              element={<EmployeeForgotPassword />}
            />
          }
        />
        <Route
          path="/forgot-password/applied"
          element={
            <EmployeePublicRoute
              path="/forgot-password/applied"
              element={<EmployeeForgotPasswordApplied />}
            />
          }
        />
        <Route
          path="/forgot-password/confirm"
          element={
            <EmployeePublicRoute
              path="/forgot-password/confirm"
              element={<EmployeeConfirmForgotPassword />}
            />
          }
        />
        <Route
          path="/calendar/weeks"
          element={
            <EmployeePrivateRoute
              path="/calendar/weeks"
              element={
                <CommonTemplate>
                  <CalendarOfWeeksPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/calendar/months"
          element={
            <EmployeePrivateRoute
              path="/calendar/months"
              element={
                <CommonTemplate>
                  <CalendarOfMonthsPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/profile"
          element={
            <EmployeePrivateRoute
              path="/profile"
              element={
                <CommonTemplate>
                  <EmployeeProfileShowPage />
                </CommonTemplate>
              }
            />
          }
        />
        <Route
          path="/settings/*"
          element={
            <EmployeePrivateRoute
              path="/settings/*"
              element={
                <CommonTemplate>
                  <EmployeeSettingsPage />
                </CommonTemplate>
              }
            ></EmployeePrivateRoute>
          }
        />
        <Route
          path="/calendar/meetingroom"
          element={
            <EmployeePrivateRoute
              path="/calendar/meetingroom"
              element={<MeetingRoomCalendar />}
            ></EmployeePrivateRoute>
          }
        />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </>
  )
}
