import { yupResolver } from "@hookform/resolvers/yup"
import * as Yup from "yup"

import React, { useState, useEffect, useContext } from "react"
import { SubmitHandler, Controller, useForm } from "react-hook-form"
import { useNavigate, useSearchParams } from "react-router-dom"

import {
  Box,
  Typography,
  styled,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material"

// import { LocalizationProvider, TimePicker } from "@mui/x-date-pickers" // (基本予約時間設定タスクで使う)
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns" // (基本予約時間設定タスクで使う)
import { api } from "../../../api/config/axiosConfig"
import { AlertError } from "../../../components/public/AlertError"
import { AlertSuccess } from "../../../components/public/AlertSuccess"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useProfile } from "../../../models/employee/useProfile"
import { UpdateProfileRequestType } from "../../../models/employee/useProfile/type"
import { useCompanyRelations } from "../../../models/public/useCompanyRelations"
import { Branch } from "../../../models/public/useCompanyRelations/type"
import { AuthContext } from "../../../providers/AuthProvider"
import enTranslations from "../../../translations/employeeSettings/employeeSettingsMain/en"
import jaTranslations from "../../../translations/employeeSettings/employeeSettingsMain/ja"
// import { EnvironmentSettingsComponent } from "./EnvironmentSettings"（言語切り替え実装時に使用）
import { ExternalSystemLinkComponent } from "./ExternalSystemLink"

const CustomMainBox = styled(Box)(({ theme }) => ({
  margin: theme.spacing(1.875, 1.875, 0, 1.875),
}))

const CustomFooter = styled(Box)(({ theme }) => ({
  marginRight: theme.spacing(1.875),
  height: "20px",
  display: "flex",
  justifyContent: "end",
  alignItems: "center",
}))

const FormElementBasicStyle = {
  backgroundColor: "#FFFFFF",
  padding: "16px 16px",
}

const FormElementRowStyle = {
  ...FormElementBasicStyle,
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
}

// const FormElementColumnStyle = {
//   ...FormElementBasicStyle,
//   display: "flex",
//   flexDirection: "column",
//   alignItems: "start",
//   justifyContent: "start",
// } // (プロフィール紹介文設定タスクで使う)

type TokenCheck = {
  m_company_token: boolean
  m_login_user_token: boolean
  g_company_token: boolean
  g_login_user_token: boolean
}

// 有効なレイアウトを持つフロアのみをフィルタリングする関数を追加
const getAvailableFloors = (branch: Branch | undefined) => {
  if (!branch) return []
  const floors = branch.floors
  const tomorrow = new Date()
  tomorrow.setHours(0, 0, 0, 0)
  tomorrow.setDate(tomorrow.getDate() + 1)

  return floors.filter((floor) => {
    return floor.layouts?.some(
      (layout: any) =>
        layout.post_status === "released" &&
        new Date(layout.release_date) < tomorrow
    )
  })
}

export const EmployeeSettingsPage = () => {
  // コンテキスト系
  const navigate = useNavigate()
  const { language, setLanguage } = useLanguage()
  const { fetchEmployeeProfile, employeeProfile, onUpdateSubmit } = useProfile()
  const { fetchCompanyRelations, companyRelations } = useCompanyRelations()
  const { currentUser } = useContext(AuthContext)
  const userID = currentUser ? currentUser.attributes.sub : "default"
  const translations = language === "en" ? enTranslations : jaTranslations

  // フォーム系の変数や型
  interface ProfileEditFormSchema extends UpdateProfileRequestType {
    preferredLanguage: string
  }
  const profileEditValidationSchema = Yup.object().shape({
    id: Yup.number(),
    branch_id: Yup.number(),
    floor_id: Yup.number(),
    preferredLanguage: Yup.string(),
  })

  const { register, control, watch, setValue, handleSubmit, reset } =
    useForm<ProfileEditFormSchema>({
      resolver: yupResolver(profileEditValidationSchema),
      defaultValues: {
        branch_id: employeeProfile.branch.id || -1,
        floor_id: employeeProfile.floor.id || -1,
        preferredLanguage: localStorage.getItem(`language_${userID}`) || "ja",
      },
    })

  // 一時変数や状態管理など
  // 外部連携のitemがoutlookかgoogleかを保持
  const [selectedItem, setSelectedItem] = useState<string>("")
  const branchList = companyRelations.branches
  const selectedBranchId = Number(watch("branch_id"))
  const selectedBranch = branchList.find((branch) => {
    return branch.id === selectedBranchId
  })
  const [showSuccessMessage, setShowSuccessMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const successMessage = "設定を保存しました"
  const errorMessage = "設定の保存に失敗しました"

  const onSubmit: SubmitHandler<ProfileEditFormSchema> = async (data) => {
    try {
      setShowSuccessMessage(false)
      setShowErrorMessage(false)
      const { preferredLanguage, ...requestData } = data
      await onUpdateSubmit(requestData)
      localStorage.setItem(`language_${userID}`, preferredLanguage)
      setLanguage(preferredLanguage)
      reset({
        branch_id: data.branch_id,
        floor_id: data.floor_id,
        preferredLanguage: data.preferredLanguage,
      })
      setShowSuccessMessage(true)
      setShowErrorMessage(false)
    } catch (error) {
      setShowSuccessMessage(false)
      setShowErrorMessage(true)
    }
  }

  const handleOpenModal = (item: string) => {
    setSelectedItem(item)
  }
  const handleCloseModal = () => {
    setSelectedItem("")
  }
  // 接続状態を保持
  const [tokenCheck, setTokenCheck] = useState<TokenCheck>({
    m_company_token: false,
    m_login_user_token: false,
    g_company_token: false,
    g_login_user_token: false,
  })
  const [searchParams] = useSearchParams()
  // トークンの更新
  useEffect(() => {
    ;(async () => {
      if (searchParams.get("m_user_name")) {
        await api
          .post("/auth", {
            m_token: searchParams.get("m_token"),
            m_refresh_token: searchParams.get("m_refresh_token"),
            m_expires_at: searchParams.get("m_expires_at"),
            m_user_name: searchParams.get("m_user_name"),
            m_user_email: searchParams.get("m_user_email"),
            m_user_timezone:
              searchParams.get("m_user_timezone") || "Tokyo Standard Time",
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/employee/settings")
          })
      } else if (searchParams.get("g_user_name")) {
        await api
          .post("/auth", {
            g_token: searchParams.get("g_token"),
            g_refresh_token: searchParams.get("g_refresh_token"),
            g_expires_at: searchParams.get("g_expires_at"),
            g_user_name: searchParams.get("g_user_name"),
            g_user_email: searchParams.get("g_user_email"),
            g_user_timezone: searchParams.get("g_user_timezone"),
            pathname: window.location.pathname,
          })
          .then(() => {
            navigate("/employee/settings")
          })
      }
      const response = await api.get("/auth/confirm")
      setTokenCheck(response.data)
      await fetchEmployeeProfile()
      await fetchCompanyRelations()
    })()
  }, [])

  useEffect(() => {
    if (employeeProfile.branch.id && employeeProfile.floor.id) {
      reset({
        branch_id: employeeProfile.branch.id || -1,
        floor_id: employeeProfile.floor.id || -1,
        preferredLanguage: localStorage.getItem(`language_${userID}`) || "ja",
      })
    }
  }, [employeeProfile])

  return (
    <>
      <AlertSuccess
        isMessageOpen={showSuccessMessage}
        alertMessage={successMessage}
        closeMessage={() => setShowSuccessMessage(false)}
      />
      <AlertError
        isMessageOpen={showErrorMessage}
        alertMessage={errorMessage}
        closeMessage={() => setShowErrorMessage(false)}
      />
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        <CustomMainBox>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              sx={{
                maxWidth: "720px",
                margin: "0 auto",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingTop: "16px",
                }}
              >
                プロフィール設定
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "8px",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFFFFF",
                    height: "160px",
                  }}
                >
                  プロフィール設定枠
                </Box>
                {/* <Box
                sx={{
                  ...FormElementColumnStyle,
                  height: "160px",
                  gap: "4px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  紹介文
                </Typography>
                <TextField
                  placeholder="入力してください"
                  multiline={true}
                  rows={4}
                  sx={{
                    width: "100%",
                    backgroundColor: "#006DFF0A",
                    "& .MuiInputBase-root": {
                      padding: "8px 12px",
                    },
                  }}
                  variant="filled"
                ></TextField>
              </Box> // (プロフィール紹介文設定タスクで使う)*/}
                <Box
                  sx={{
                    ...FormElementRowStyle,
                    height: "72px",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                    }}
                  >
                    初期表示フロア
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                      alignItems: "center",
                    }}
                  >
                    <FormControl variant="filled" size="small" hiddenLabel>
                      <Controller
                        control={control}
                        name="branch_id"
                        render={({ field }) => (
                          <Select
                            {...field}
                            onChange={(e) => {
                              field.onChange(e)
                              const nextAvailableFloorList = getAvailableFloors(
                                branchList.find(
                                  (branch) => branch.id === e.target.value
                                ) as Branch
                              )
                              setValue(
                                "floor_id",
                                nextAvailableFloorList.length > 0
                                  ? nextAvailableFloorList[0].id
                                  : -1
                              )
                            }}
                            sx={{
                              width: "160px",
                              backgroundColor: "#006DFF0A",
                            }}
                          >
                            <MenuItem
                              value={-1}
                              disabled
                              sx={{ display: "none" }}
                            >
                              拠点名
                            </MenuItem>
                            {branchList.map((branch, index) => (
                              <MenuItem key={index} value={branch.id}>
                                {branch.branch_name}
                              </MenuItem>
                            ))}
                          </Select>
                        )}
                      />
                    </FormControl>
                    <FormControl variant="filled" size="small" hiddenLabel>
                      <Controller
                        control={control}
                        name="floor_id"
                        render={({ field }) => (
                          <Select
                            {...field}
                            sx={{
                              width: "120px",
                              backgroundColor: "#006DFF0A",
                            }}
                          >
                            <MenuItem
                              value={-1}
                              disabled
                              sx={{ display: "none" }}
                            >
                              フロア名
                            </MenuItem>
                            {selectedBranch &&
                              getAvailableFloors(selectedBranch).map(
                                (floor, index) => (
                                  <MenuItem key={index} value={floor.id}>
                                    {floor.floor_name}
                                  </MenuItem>
                                )
                              )}
                          </Select>
                        )}
                      />
                    </FormControl>
                  </Box>
                </Box>
                {/* <Box
                sx={{
                  ...FormElementRowStyle,
                  height: "72px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  基本予約時刻
                </Typography>
                <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      views={["hours", "minutes"]}
                      ampm={false}
                      timeSteps={{ minutes: 15 }}
                      minTime={new Date(0, 0, 0, 0, 0)}
                      maxTime={new Date(0, 0, 0, 23, 30)}
                      value={selectedStartTime}
                      onChange={(newValue) => setSelectedStartTime(newValue)}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "filled",
                        },
                      }}
                      sx={{
                        width: "100px",
                        height: "40px",
                        "& .MuiInputBase-root": {
                          padding: "0 12px",
                          width: "100px",
                          height: "40px",
                          backgroundColor: "#006DFF0A",
                          fontSize: "14px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "0",
                        },
                        "& .MuiIconButton-root": {
                          padding: "0 8px 0 0",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  〜
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <TimePicker
                      views={["hours", "minutes"]}
                      ampm={false}
                      timeSteps={{ minutes: 15 }}
                      minTime={
                        selectedStartTime
                          ? new Date(selectedStartTime.getTime() + 15 * 60000)
                          : new Date(0, 0, 0, 0, 15)
                      }
                      maxTime={new Date(0, 0, 0, 23, 45)}
                      slotProps={{
                        textField: {
                          size: "small",
                          variant: "filled",
                        },
                      }}
                      sx={{
                        width: "100px",
                        height: "40px",
                        "& .MuiInputBase-root": {
                          padding: "0 12px",
                          width: "100px",
                          height: "40px",
                          backgroundColor: "#006DFF0A",
                          fontSize: "14px",
                        },
                        "& .MuiInputBase-input": {
                          padding: "0",
                        },
                        "& .MuiIconButton-root": {
                          padding: "0 8px 0 0",
                        },
                      }}
                    />
                  </LocalizationProvider>
                </Box>
              </Box> // (基本予約時間設定タスクで使う) */}
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingTop: "32px",
                }}
              >
                外部連携
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  height: "160px",
                  marginTop: "8px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 16px",
                    borderBottom: "1px solid #F5F5F5",
                  }}
                >
                  Outlook
                  <Button
                    sx={{
                      border: "1px solid #22ba9d",
                      "&.Mui-disabled": {
                        borderColor: "#C5C5C5",
                      },
                    }}
                    type="submit"
                    onClick={() => handleOpenModal("MICROSOFT")}
                    disabled={
                      !tokenCheck.m_login_user_token &&
                      !tokenCheck.m_company_token
                    }
                  >
                    {tokenCheck.m_login_user_token ? "解除する" : "接続する"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "0 16px",
                  }}
                >
                  Google
                  <Button
                    sx={{
                      border: "1px solid #22ba9d",
                      "&.Mui-disabled": {
                        borderColor: "#C5C5C5",
                      },
                    }}
                    type="submit"
                    onClick={() => handleOpenModal("GOOGLE")}
                    disabled={
                      !tokenCheck.g_login_user_token &&
                      !tokenCheck.g_company_token
                    }
                  >
                    {tokenCheck.g_login_user_token ? "解除する" : "接続する"}
                  </Button>
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: "bold",
                  paddingTop: "32px",
                }}
              >
                環境設定
              </Typography>
              <Box
                sx={{
                  backgroundColor: "#FFFFFF",
                  height: "72px",
                  marginTop: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  padding: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "14px",
                  }}
                >
                  言語
                </Typography>
                <FormControl variant="filled" size="small" hiddenLabel>
                  <Controller
                    control={control}
                    name="preferredLanguage"
                    render={({ field }) => (
                      <Select
                        {...field}
                        sx={{
                          width: "160px",
                          backgroundColor: "#006DFF0A",
                        }}
                      >
                        <MenuItem value="ja">日本語</MenuItem>
                        <MenuItem value="en">英語</MenuItem>
                      </Select>
                    )}
                  />
                </FormControl>
              </Box>
              <Box sx={{ textAlign: "end" }}>
                <Button
                  sx={{
                    width: "70px",
                    height: "20px",
                    fontSize: "14px",
                    color: "#006dff",
                    margin: "32px 10px",
                    padding: "8px",
                    textAlign: "left",
                    whiteSpace: "nowrap",
                  }}
                  onClick={() => {
                    reset()
                  }}
                >
                  キャンセル
                </Button>
                <Button
                  sx={{
                    width: "88px",
                    height: "44px",
                    fontSize: "14px",
                    borderRadius: "4px",
                    boxShadow: "0px 2px 1px #00000029",
                    backgroundColor: "#006dff",
                    color: "#ffffff",
                    margin: "32px, 0",
                  }}
                  type="submit"
                >
                  保存する
                </Button>
              </Box>
            </Box>
          </form>
        </CustomMainBox>
        {selectedItem && (
          <CustomMainBox>
            <ExternalSystemLinkComponent
              selectedItem={selectedItem}
              onCloseModal={handleCloseModal}
              tokenCheck={tokenCheck}
              setTokenCheck={setTokenCheck}
            />
          </CustomMainBox>
        )}
      </Box>
      <CustomFooter>
        <Typography
          sx={{
            fontSize: "10px",
            fontFamily: "Noto Sans",
            lineHeight: "20px",
          }}
          variant="overline"
        >
          <a
            href="https://workagile.jp/index.php/privacy/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
            }}
          >
            {translations.TermsService}{" "}
          </a>
          <a
            href="https://workagile.jp/index.php/terms/"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              color: "#707070",
            }}
          >
            {translations.PrivacyPolicy}
          </a>
        </Typography>
      </CustomFooter>
    </>
  )
}
