const en = {
  twoFactorAuth: "Two-factor authentication",
  Integrations: "Integrations",
  environmentSettings: "Environment Settings",
  PrivacyPolicy: "Privacy Policy",
  and: "&",
  TermsService: "Terms of Service",
  Settings: "SETTINGS",
  Disconnect: "Disconnect",
  Connect: "Connect",
  Cancel: "Cancel",
  Save: "Save",
  // エラーメッセージ
  NotTwoFactor: "Two-factor Authentication failed",
  ShowQRCode: "Show QR code",
  PINCode: "PIN code (required)",
  verificationCode: "Please enter the verification code.",
  verificationAlready: "2-step verification is already set.",
  Create: "Create",
  digitsCode: "Enter the 6 digits code",
  halfWidthNumbers: "Use half-width numbers",
  Required: "Required",
}
export default en
