const ja = {
  checkInOut: "座席チェックイン/アウト",
  status: "予約状況/出社情報",
  seatReserve: "座席予約",
  NewMessage: "新着メッセージ",
  Subject: "件名",
  From: "差出人",
  Repeat: "リピート",
  Location: "拠点",
  dates: "日付",
  TermsService: "利用規約",
  and: "と",
  PrivacyPolicy: "プライバシーポリシー",
  everyWeek: "毎週",
  everyWeeks: "週ごとの",
  weekdays: ["日", "月", "火", "水", "木", "金", "土"],
  week: "曜日",
  date_format: "yyyy年MM月dd日",
  LayoutNot: "現在レイアウトが公開されていません。管理者様にご確認ください。",
  noAvailableFloors: "公開中のフロアはありません",
  pleaseSelectFloor: "フロアを選択してください",
}
export default ja
