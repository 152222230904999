import {
  ProfileResponseType,
  UpdateProfileRequestType,
} from "../../models/employee/useProfile/type"
import { api, ApiResponse } from "../config/axiosConfig"

export const fetchProfileRequest = async (): Promise<
  ApiResponse<ProfileResponseType>
> => {
  try {
    const data = await api.get("/employee/profile")
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}

export const updateProfileRequest = async (
  body: UpdateProfileRequestType
): Promise<ApiResponse<void>> => {
  try {
    const data = await api.patch("/employee/profile", {
      employee: body,
    })
    return data
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return { error: error.response.data }
  }
}
