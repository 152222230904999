import React, { ReactNode, useEffect, useState } from "react"
import { Navigate, useLocation } from "react-router-dom"

import { Box, styled } from "@mui/material"

import { Header } from "../components/public/Header"
import { Loading } from "../components/public/Loading"
import { CompanySidebar, EmployeeSidebar } from "../components/public/Sidebar"
import { useCompany } from "../models/company/useCompany"
import { useIcon } from "../models/public/useIcon"

const CustomHeader = styled(Header)(() => ({
  zIndex: 100,
}))

const CustomMainContent = styled(Box)({
  width: "100%",
  backgroundColor: "#F7F7F7",
  borderTopLeftRadius: "10px",
  flexGrow: 1,
  overflowY: "scroll",
  "&::-webkit-scrollbar": {
    display: "none",
  },
})

interface LocationStateProps {
  updatedEmployeeId: number
}

export const CommonTemplate = ({ children }: { children: ReactNode }) => {
  const companyObject = useCompany()
  const iconObject = useIcon()
  const headerObject = companyObject.headerCompanyInfo
  const location = useLocation()
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    companyObject.fetchCompany().then(() => {
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    if (headerObject && !headerObject.is_temporary) {
      iconObject.fetchFile(headerObject.company.id, headerObject.employee.id)
    }
  }, [headerObject])

  // 従業員編集でログイン中ユーザーの情報を編集した際に
  // location.stateから変更された従業員のidを受け取り最新のデータと同期する→ヘッダーに反映
  useEffect(() => {
    const locationState = location.state as LocationStateProps
    if (locationState?.updatedEmployeeId) {
      if (locationState?.updatedEmployeeId === headerObject?.employee.id) {
        const refetchData = async () => {
          await Promise.all([
            companyObject.fetchCompany(),
            iconObject.fetchFile(
              headerObject.company.id,
              headerObject.employee.id
            ),
          ])
        }
        refetchData()
      }
    }
  }, [location.state])

  return (
    <>
      {loading ? (
        <Loading type="fullScreen" />
      ) : (
        <>
          {headerObject ? (
            <Box sx={{ display: "flex", height: "100vh" }}>
              <Box
                sx={{
                  width: "64px",
                  height: "100vh",
                  zIndex: 200,
                  position: "relative",
                }}
              >
                {location.pathname.startsWith("/company/") ? (
                  <CompanySidebar />
                ) : (
                  <EmployeeSidebar />
                )}
              </Box>
              <Box
                sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "50px",
                    zIndex: 100,
                    position: "relative",
                  }}
                >
                  <CustomHeader
                    {...companyObject}
                    {...iconObject}
                    loading={iconObject.loading}
                  />
                </Box>
                <CustomMainContent>{children}</CustomMainContent>
              </Box>
            </Box>
          ) : (
            <Navigate to="/company/register-info" />
          )}
        </>
      )}
    </>
  )
}
