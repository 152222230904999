import { api, ApiResponse } from "../../config/axiosConfig"

// スケジュールチェックインのリクエストインターフェース
interface ScheduleCheckInRequestParams {
  scheduleId: number
}

// スケジュールチェックインのレスポンス型を定義
interface ScheduleCheckInResponse {
  // レスポンスの具体的な構造に応じて適切なプロパティを追加
  success: boolean
  message: string
}

// スケジュールチェックインAPI関数
export const scheduleCheckInRequest = async (
  params: ScheduleCheckInRequestParams
): Promise<ApiResponse<ScheduleCheckInResponse>> => {
  try {
    const response = await api.post<ScheduleCheckInResponse>(
      "/mobile/home/schedule_check_in",
      {
        schedule_id: params.scheduleId,
      }
    )
    return {
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error("スケジュールチェックインエラー", error)
    throw error
  }
}
