import { api, ApiResponse } from "../../config/axiosConfig"

// スケジュール取り消しのリクエストインターフェース
interface ScheduleCancelRequestParams {
  scheduleId: number
}

// スケジュール取り消しのレスポンス型を定義
interface ScheduleCancelResponse {
  // レスポンスの具体的な構造に応じて適切なプロパティを追加
  success: boolean
  message: string
}

// スケジュール取り消しAPI関数
export const scheduleCancelRequest = async (
  params: ScheduleCancelRequestParams
): Promise<ApiResponse<ScheduleCancelResponse>> => {
  try {
    const response = await api.post<ScheduleCancelResponse>(
      "/mobile/home/schedule_cancel",
      {
        schedule_id: params.scheduleId,
      }
    )
    return {
      data: response.data,
      status: response.status,
    }
  } catch (error) {
    console.error("スケジュール取り消しエラー", error)
    throw error
  }
}
