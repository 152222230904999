import PropTypes from "prop-types"

import React, { memo, useState } from "react"

import { List, Box, styled } from "@mui/material"

import { MyScheduleRequestType } from "../../../models/employee/useSchedule/useScheduleHome/type"
import { oneWeek } from "../../../utils/oneWeek"
import { weekStatus } from "../../../utils/weeks"
import { companyListItems, EmployeeListItems } from "./ListItems"

export const MenuList = styled(Box)(() => ({
  height: "100vh",
  display: "flex",
  borderTop: "none",
  overflowY: "auto",
  overflowX: "hidden",
  backgroundColor: "#22ba9d",
}))

export const CustomList = styled(List)(() => ({}))

export const CompanySidebar: React.FC = memo(() => {
  return (
    <MenuList
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box sx={{ paddingTop: "13px" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/logo-icon.svg`}
          height="25px"
          width="25px"
          alt="WORK AGILEロゴ"
        />
      </Box>
      <CustomList>{companyListItems()}</CustomList>
    </MenuList>
  )
})

CompanySidebar.propTypes = {
  isTeamLeader: PropTypes.bool.isRequired,
}

export const EmployeeSidebar: React.FC = memo(() => {
  const weekObject = weekStatus()
  const { setTargetWeeks } = oneWeek()

  const [beginningOfWeek, setBeginningOfWeek] = useState(new Date())
  const [endOfWeek, setEndOfWeek] = useState(new Date())

  const formatDate = (date: Date) => date.toISOString().split("T")[0]

  const fetchMySchedule = async (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data: MyScheduleRequestType
  ): Promise<void> => {
    const { newBeginningOfWeek, newEndOfThisWeek } = setTargetWeeks(new Date())
    await fetchMySchedule({
      start_date: weekObject.formatDate(newBeginningOfWeek),
      end_date: weekObject.formatDate(newEndOfThisWeek),
    })
  }

  const props = {
    setBeginningOfWeek,
    setEndOfWeek,
    formatDate,
    fetchMySchedule,
  }
  return (
    <MenuList
      sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Box sx={{ paddingTop: "13px" }}>
        <img
          src={`${process.env.PUBLIC_URL}/images/logos/logo-icon.svg`}
          height="25px"
          width="25px"
          alt="WORK AGILEロゴ"
        />
      </Box>
      <CustomList>
        <EmployeeListItems {...props} />
      </CustomList>
    </MenuList>
  )
})
