import React, { memo } from "react"

import { Box } from "@mui/material"

import { AreaType } from "../../../models/employee/useLayout/type"
import { areaIconStyle, areaStyle } from "../../../styles/areaStyle"

interface AreaProps {
  areaId: number | null
  areaName: string
  startX: number
  startY: number
  endX: number
  endY: number
  colorCode: string
  meetingRoomCode: boolean
}

const AreaObject = ({
  areaId,
  areaName,
  startX,
  startY,
  endX,
  endY,
  colorCode,
  meetingRoomCode,
}: AreaProps) => {
  return (
    <Box
      id={`A${String(areaId)}`}
      className={"area"}
      sx={areaStyle(startX, startY, endX, endY, colorCode)}
    >
      <div
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
          flexDirection: "column",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: colorCode,
          fontSize: "30px",
        }}
      >
        {areaName}
      </div>
      {/* エリア会議室化の表示 */}
      {meetingRoomCode && (
        <>
          <span style={areaIconStyle(colorCode) as React.CSSProperties}>M</span>
          <span
            style={{
              top: "60px",
              left: "60px",
              color: colorCode,
              position: "absolute",
              fontSize: "30px",
            }}
          >
            {meetingRoomCode}
          </span>
        </>
      )}
    </Box>
  )
}

interface Props {
  area: AreaType
}

/*
  レイアウト画像に会議室のアイコンが表示される
*/
export const EmployeeHomeLayoutArea: React.FC<Props> = ({ area }) => {
  return (
    <AreaObject
      areaId={area.id}
      areaName={area.area_name}
      startX={area.start_x}
      startY={area.start_y}
      endX={area.end_x}
      endY={area.end_y}
      colorCode={area.color_code}
      meetingRoomCode={area.is_area_to_meeting_room}
    />
  )
}
