import React, { memo, useEffect } from "react"
import { useNavigate, useLocation, useParams } from "react-router-dom"

import {
  Box,
  Typography,
  Grid,
  AppBar,
  styled,
  IconButton,
} from "@mui/material"

import { BackIcon } from "../../../components/public/BackIcon"
import { theme } from "../../../config/theme"
import { useLanguage } from "../../../contexts/LanguageContext"
import { useBranch } from "../../../models/company/useBranch"
import { HeaderCompanyType } from "../../../models/company/useCompany/type"
import enTranslations from "../../../translations/headerTitle/en"
import jaTranslations from "../../../translations/headerTitle/ja"
import { AlertSuccess } from "../AlertSuccess"
import { HeaderMenu } from "./HeaderMenu"

const CustomTitleTypography = styled(Typography)(() => ({
  fontSize: "18px",
  fontWeight: "bold",
  letterSpacing: "0.9px",
  lineHeight: "2.4",
}))

const CustomAppBar = styled(AppBar)(() => ({
  width: "100%",
  height: "50px",
  padding: "0 30px 0 20px",
}))

const CustomToolbar = styled(Grid)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "100%",
}))

const CompanyContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}))

const CustomIconButton = styled(IconButton)(() => ({
  padding: "0",
}))

const EmployeeContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
}))

const EmployeeIcon = styled(Box)(() => ({
  border: `1px solid ${theme.palette.secondary.main}`,
  borderRadius: "50%",
  height: "25px",
  width: "25px",
  textAlign: "center",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  marginRight: "15px",
}))

const CustomTypography = styled(Typography)(() => ({
  color: "#454545",
  fontFamily: "Noto Sans",
}))

interface Props {
  headerCompanyInfo?: HeaderCompanyType
  imageUrl: string
  uploaded: boolean
  uploadSuccessMessage: string
  setUploaded: React.Dispatch<React.SetStateAction<boolean>>
  loading: boolean
  handleCreateIcon: (
    file: Blob,
    companyId: number,
    employeeId: number
  ) => Promise<void>
}

type PathListType = {
  title: () => JSX.Element
  url: string[]
}

// TODO
// ベルアイコンを追加する
export const Header: React.FC<Props> = memo(
  ({
    headerCompanyInfo,
    imageUrl,
    uploaded,
    uploadSuccessMessage,
    setUploaded,
    loading,
    handleCreateIcon,
  }: Props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
      setAnchorEl(null)
    }

    const handleUploadSuccessMessageClose = () => {
      setUploaded(false)
    }

    const companyUrlParams = useParams<{
      id: string
      branch_id: string
      floor_id: string
      layout_id: string
    }>()

    const employeeUrlParams = useParams<{
      id: string
    }>()

    // 言語切り替え
    const { language } = useLanguage()
    const translations = language === "en" ? enTranslations : jaTranslations

    // URLによってタイトルを変更
    const CompanyHome = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.home}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyBranches = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.locationInformation}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesQrcode = () => {
      const { showBranch, branchDetail } = useBranch()

      useEffect(() => {
        showBranch()
      }, [showBranch])

      return (
        <>
          <BackIcon handleClick={() => navigate("/company/branches")} />
          <CustomTitleTypography>
            {translations.locationInformation} {branchDetail.branch_name}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesNew = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/branches")} />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.locationInformation}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.signUp}</CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesShow = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/branches")} />
          <CustomTitleTypography>
            {translations.locationInformation}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesEdit = () => {
      return (
        <>
          <BackIcon
            handleClick={() =>
              navigate(`/company/branches/${companyUrlParams.id}`)
            }
          />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.locationInformation}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.edit}</CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesLayoutsNew = () => {
      const matchBranchId = location.pathname.match(
        /\/company\/branches\/(\d+)/
      )
      const branch_id = matchBranchId ? matchBranchId[1] : ""

      return (
        <>
          <BackIcon
            handleClick={() => navigate(`/company/branches/${branch_id}`)}
          />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.layouts}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.signUp}</CustomTitleTypography>
        </>
      )
    }

    const CompanyBranchesLayoutsEdit = () => {
      const matchBranchId = location.pathname.match(
        /\/company\/branches\/(\d+)/
      )
      const branch_id = matchBranchId ? matchBranchId[1] : ""

      return (
        <>
          <BackIcon
            handleClick={() => navigate(`/company/branches/${branch_id}`)}
          />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.layouts}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.edit}</CustomTitleTypography>
        </>
      )
    }

    const CompanyEmployees = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.employeeManagement}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyEmployeesNew = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/employees")} />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.employeeInformation}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.signUp}</CustomTitleTypography>
        </>
      )
    }

    const CompanyEmployeesShow = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/employees")} />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.employeeInformation}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.detail}</CustomTitleTypography>
        </>
      )
    }

    const CompanyEmployeesEdit = () => {
      const matchEmployeeId = location.pathname.match(
        /\/company\/employees\/(\d+)/
      )
      const employee_id = matchEmployeeId ? matchEmployeeId[1] : ""

      return (
        <>
          <BackIcon
            handleClick={() => navigate(`/company/employees/${employee_id}`)}
          />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.employeeInformation}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.edit}</CustomTitleTypography>
        </>
      )
    }

    const CompanyTeams = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.termList}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyTeamsNew = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/teams")} />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.corporateOrganization}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.signUp}</CustomTitleTypography>
        </>
      )
    }

    const CompanyTeamsEdit = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate(`/company/teams/`)} />
          <CustomTitleTypography sx={{ marginRight: "5px" }}>
            {translations.corporateOrganization}
          </CustomTitleTypography>
          <CustomTitleTypography>{translations.edit}</CustomTitleTypography>
        </>
      )
    }

    const CompanyReports = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.report}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyReportsAttendances = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/reports")} />
          <CustomTitleTypography>
            {translations.attendanceRate}・{translations.operationRate}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyReportsOperations = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/reports")} />
          <CustomTitleTypography>
            {translations.byLocation}・{translations.operationSeatCount}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyReportsMeetingRooms = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/reports")} />
          <CustomTitleTypography>
            {translations.conferenceRoomUsageStatus}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanyReportsAreas = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/reports")} />
          <CustomTitleTypography>
            {translations.byAreaUsageStatus}
          </CustomTitleTypography>
        </>
      )
    }

    const CompanySetting = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/company/home")} />
          <CustomTitleTypography>{translations.settings}</CustomTitleTypography>
        </>
      )
    }

    const EmployeeCalendar = () => {
      return (
        <>
          <CustomTitleTypography sx={{ marginLeft: "10px" }}>
            {translations.schedule}
          </CustomTitleTypography>
        </>
      )
    }

    const EmployeeCalendarNew = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/employee/calendar/days")} />
          <CustomTitleTypography>
            {translations.newSchedule}
          </CustomTitleTypography>
        </>
      )
    }

    const EmployeeCalendarEdit = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/employee/calendar/days")} />
          <CustomTitleTypography>
            {translations.editSchedule}
          </CustomTitleTypography>
        </>
      )
    }

    const EmployeeLayouts = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/employee/home")} />
          <CustomTitleTypography>
            {translations.workPlace}
          </CustomTitleTypography>
        </>
      )
    }

    const EmployeeSettings = () => {
      return (
        <>
          <BackIcon handleClick={() => navigate("/employee/home")} />
          <CustomTitleTypography>{translations.settings}</CustomTitleTypography>
        </>
      )
    }
    // URLによってタイトルを変更 END

    const pathList: PathListType[] = [
      {
        // ホーム
        title: CompanyHome,
        url: ["/company/home", "/company/dashboard", "/employee/home"],
      },
      {
        // 拠点管理
        title: CompanyBranches,
        url: ["/company/branches"],
      },
      {
        // 拠点管理 > QRコード
        title: CompanyBranchesQrcode,
        url: [
          `/company/branches/${companyUrlParams.id}/floors/${companyUrlParams.floor_id}/qrcode`,
        ],
      },
      {
        // 拠点管理 > 新規登録
        title: CompanyBranchesNew,
        url: ["/company/branches/new"],
      },
      {
        // 拠点管理 > 詳細
        title: CompanyBranchesShow,
        url: [`/company/branches/${companyUrlParams.id}`],
      },
      {
        // 拠点管理 > 編集
        title: CompanyBranchesEdit,
        url: [`/company/branches/${companyUrlParams.id}/edit`],
      },
      {
        // レイアウト > 新規登録
        title: CompanyBranchesLayoutsNew,
        url: [
          `/company/branches/${companyUrlParams.branch_id}/floors/${companyUrlParams.floor_id}/layouts/new`,
        ],
      },
      {
        // レイアウト > 編集
        title: CompanyBranchesLayoutsEdit,
        url: [
          `/company/branches/${companyUrlParams.branch_id}/floors/${companyUrlParams.floor_id}/layouts/${companyUrlParams.layout_id}`,
        ],
      },
      {
        // 従業員管理
        title: CompanyEmployees,
        url: ["/company/employees"],
      },
      {
        // 従業員管理 > 新規登録
        title: CompanyEmployeesNew,
        url: ["/company/employees/new"],
      },
      {
        // 従業員管理 > 詳細
        title: CompanyEmployeesShow,
        url: [`/company/employees/${companyUrlParams.id}`],
      },
      {
        // 従業員管理 > 編集
        title: CompanyEmployeesEdit,
        url: [`/company/employees/${companyUrlParams.id}/edit`],
      },
      {
        // 組織管理
        title: CompanyTeams,
        url: ["/company/teams"],
      },
      {
        // 組織管理 > 新規登録
        title: CompanyTeamsNew,
        url: ["/company/teams/new"],
      },
      {
        // 組織管理 > 編集
        title: CompanyTeamsEdit,
        url: [`/company/teams/${companyUrlParams.id}/edit`],
      },
      {
        // レポート
        title: CompanyReports,
        url: ["/company/reports"],
      },
      {
        // レポート > 出社率・稼働率
        title: CompanyReportsAttendances,
        url: ["/company/reports/attendances"],
      },
      {
        // レポート > 拠点別・稼働席数
        title: CompanyReportsOperations,
        url: ["/company/reports/operations"],
      },
      {
        // レポート > 会議室利用状況
        title: CompanyReportsMeetingRooms,
        url: ["/company/reports/meeting_rooms"],
      },
      {
        // レポート > エリア別利用状況
        title: CompanyReportsAreas,
        url: ["/company/reports/areas"],
      },
      {
        // 設定
        title: CompanySetting,
        url: [
          "/company/settings/payment",
          "/company/settings/external-system-link",
          "/company/settings/setup-mfa",
          "/company/settings/environment",
          "/company/settings/environment/work_hour",
          "/company/settings/environment/weekly_off_day",
          "/company/settings/environment/signage",
          "/company/settings/payment/methods",
          "/company/settings/payment/paid",
          "/company/settings/payment/credit_card",
          "/company/settings/payment/plan",
        ],
      },
      {
        // 予定表
        title: EmployeeCalendar,
        url: [
          "/employee/calendar/days",
          "/employee/calendar/weeks",
          "/employee/calendar/months",
          "/employee/calendar/layouts",
        ],
      },
      {
        // 新規スケジュール
        title: EmployeeCalendarNew,
        url: ["/employee/calendar/new"],
      },
      {
        // スケジュール編集
        title: EmployeeCalendarEdit,
        url: [`/employee/calendar/edit/${employeeUrlParams.id}`],
      },
      {
        // 席状況
        title: EmployeeLayouts,
        url: ["/employee/layouts", "/employee/layouts/status"],
      },
      {
        // 設定
        title: EmployeeSettings,
        url: [
          "/employee/settings",
          "/employee/settings/setup-mfa",
          "/employee/settings/external-system-link",
          "/employee/settings/environment-settings",
        ],
      },
    ]

    const findTitleByUrl = (
      currentPath: string,
      pathList: PathListType[]
    ): JSX.Element | null => {
      const matchedItem = pathList.find((item) =>
        item.url.includes(currentPath)
      )
      return matchedItem ? matchedItem.title() : null
    }

    const currentPath = location.pathname
    const nameForCurrentPath = findTitleByUrl(currentPath, pathList)

    return (
      <>
        <CustomAppBar color="inherit" elevation={0} position="relative">
          <CustomToolbar>
            <CompanyContent>
              <Box display="flex" alignItems="center" marginLeft="10px">
                {nameForCurrentPath}
              </Box>
            </CompanyContent>
            <EmployeeContent>
              <EmployeeIcon onClick={(e) => handleMenu(e)} id="employee_icon">
                {imageUrl === "" ? (
                  <CustomTypography
                    sx={{
                      variant: "button",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      fontSize: "10px",
                    }}
                  >
                    {headerCompanyInfo?.employee.last_name[0]}
                    {headerCompanyInfo?.employee.first_name[0]}
                  </CustomTypography>
                ) : (
                  <img
                    src={imageUrl}
                    style={{
                      borderRadius: "50%",
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                )}
              </EmployeeIcon>
              <HeaderMenu
                anchorEl={anchorEl}
                handleClose={handleClose}
                {...headerCompanyInfo}
                imageUrl={imageUrl}
                loading={loading}
                handleCreateIcon={handleCreateIcon}
              />
              <CustomTypography
                sx={{
                  fontSize: "12px",
                  letterSpacing: "0.6px",
                  lineHeight: "1.7",
                }}
              >
                {headerCompanyInfo?.employee.last_name}
              </CustomTypography>
              <p
                style={{
                  margin: "0 2.5px",
                }}
              ></p>
              <CustomTypography
                sx={{
                  fontSize: "12px",
                  letterSpacing: "0.6px",
                  lineHeight: "1.7",
                }}
              >
                {headerCompanyInfo?.employee.first_name}
              </CustomTypography>
              <p
                style={{
                  height: "12px",
                  borderRight: "1px solid #e3e3e3",
                  margin: "0 15px",
                }}
              ></p>
              <CustomTypography
                sx={{
                  fontSize: "12px",
                  letterSpacing: "0.6px",
                  lineHeight: "1.7",
                }}
              >
                {headerCompanyInfo?.company.company_name}
              </CustomTypography>
            </EmployeeContent>
          </CustomToolbar>
        </CustomAppBar>
        <AlertSuccess
          isMessageOpen={uploaded}
          alertMessage={uploadSuccessMessage}
          closeMessage={handleUploadSuccessMessageClose}
        />
      </>
    )
  }
)
