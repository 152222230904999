import { useState, useCallback } from "react"
import { SubmitHandler } from "react-hook-form"

import {
  fetchProfileRequest,
  updateProfileRequest,
} from "../../../api/employee/profileRequest"
import { ProfileResponseType, UpdateProfileRequestType } from "./type"

const profileResponseData: ProfileResponseType = {
  id: 0,
  last_name: "",
  first_name: "",
  last_name_kana: "",
  first_name_kana: "",
  account: {
    id: 0,
    email: "",
  },
  employee_code: "",
  phone_number: "",
  remote_flag: false,
  note: "",
  is_available: true,
  floor: {
    id: 0,
    floor_number: "",
    floor_name: "",
  },
  seat_id: 0,
  branch: {
    id: 0,
    branch_name: "",
  },
  team: {
    id: 0,
    team_name: "",
    team_leader_name: "",
    team_leader_email: "",
  },
}

export const useProfile = () => {
  const [employeeProfile, setEmployeeProfile] =
    useState<ProfileResponseType>(profileResponseData)

  const fetchEmployeeProfile = useCallback(async () => {
    try {
      const response = await fetchProfileRequest()
      if (response.status === 200 && response.data) {
        setEmployeeProfile(response.data)
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      console.log("error")
    }
  }, [])

  const onUpdateSubmit: SubmitHandler<UpdateProfileRequestType> = useCallback(
    async (params) => {
      try {
        const { data, error } = await updateProfileRequest({
          ...params,
        })
        if (data) {
          setEmployeeProfile(data)
        } else if (error) {
          console.log(error)
        }
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (error: any) {
        console.log(error)
        throw error
      }
    },
    []
  )

  return {
    employeeProfile,
    fetchEmployeeProfile,
    onUpdateSubmit,
  }
}
