const ja = {
  Disconnect: "解除する",
  Connect: "接続する",
  Cancel: "キャンセル",
  ConnectOutLook: "Outlookとの接続",
  ConnectMicrosoftAccount:
    "microsoftアカウントに接続して、Outlookカレンダーの予定と連携します。",
  ConnectGoogle: "Googleとの接続",
  ConnectGoogleAccount:
    "Googleアカウントに接続して、 Googleカレンダーの予定と連携します。",
  DisconnectGoogle: "Googleカレンダーとの解除",
  DisconnectGoogleCalendar: "Googleカレンダーの予定との連携を解除します。",
  CancelOutlook: "Outlookとの解除",
  UnlinkFromOutlook: "Outlookカレンダーの予定との連携を解除します。",
}
export default ja
