import React from "react"

import { Box } from "@mui/material"

import { MobileHomeSchedule } from "../../../components/mobile/home/main"
import { AlertContent } from "../../../components/public/AlertContent"

export const MobileSchedulePage = () => {
  return (
    <Box
      sx={{
        margin: "10px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <MobileHomeSchedule />
      <AlertContent />
    </Box>
  )
}
